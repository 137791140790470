import colors from "../../themes/colors"
let styles = {
	container: {
		backgroundColor: colors.gaipBlue,
		height: 10,
		borderTopLeftRadius: 10,
		borderTopRightRadius: 10
	}
}

export default styles