import { useTranslation } from 'react-i18next';
import styles from './styles.js'
import { Slide, Zoom, Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import {
	Container,
	Row,
	Col,
	Nav,
	Navbar,
	NavDropdown,
	Image,
	Form,
	FormControl,
	Button,
	Carousel,
} from 'react-bootstrap'
import  { Breakpoint, BreakpointProvider } from 'react-socks';

import Footer from "../../components/footer-component"
import colors from "../../themes/colors"

import image1 from '../../assets/sliders/1.jpg'
import banner from '../../assets/services/banner_servicios.png'
import service1 from '../../assets/services/servicio_1.png'
import service2 from '../../assets/services/servicio_2.png'
import service3 from '../../assets/services/servicio_3.png'
import service4 from '../../assets/services/servicio_4.png'
import service5 from '../../assets/services/servicio_5.png'
import service6 from '../../assets/services/servicio_6.png'

function Services() {
	const { t, i18n } = useTranslation();
	return (
		<Container fluid>
			<Breakpoint medium up>
				<Col sm={{span: 10, offset: 1}} xs={{span: 12, offset: 0}}>
					<Row>
						<Col style={styles.servicesHeader} sm={{ span: 10, offset: 1 }} xs={{span: 12, offset: 0}}>
							<h2 style={styles.headerTitle}>
								{t("services.servicesHeader.title")}
							</h2>
							<h5 style={styles.headerText}>
								{t("services.servicesHeader.text")}
							</h5>
							<Image style={styles.serviceImage} src={banner} fluid/>
						</Col>
					</Row>
					<Row style={styles.serviceRow}>
						<Col sm="3" xs="12">
							<Image style={styles.serviceImage} src={service1} fluid/>
						</Col>
						<Col sm="9" xs="12">
							<h3 style={styles.serviceTitle}>
								{t("services.service1.title")}
							</h3>
							<h5 style={styles.serviceText}>
								{t("services.service1.text")}
							</h5>
						</Col>
					</Row>
					<Row style={styles.serviceRow}>
						<Col sm="9" xs="12">
							<h3 style={styles.serviceTitle}>
								{t("services.service2.title")}
							</h3>
							<h5 style={styles.serviceText}>
								{t("services.service2.text")}
							</h5>
						</Col>
						<Col sm="3" xs="12">
							<Image style={styles.serviceImage} src={service2} fluid/>
						</Col>
					</Row>
					<Row style={styles.serviceRow}>
						<Col sm="3" xs="12">
							<Image style={styles.serviceImage} src={service3} fluid/>
						</Col>
						<Col sm="9" xs="12">
							<h3 style={styles.serviceTitle}>
								{t("services.service3.title")}
							</h3>
							<h5 style={styles.serviceText}>
								{t("services.service3.text")}
							</h5>
						</Col>
					</Row>
					<Row style={styles.serviceRow}>
						<Col sm="9" xs="12">
							<h3 style={styles.serviceTitle}>
								{t("services.service4.title")}
							</h3>
							<h5 style={styles.serviceText}>
								{t("services.service4.text")}
							</h5>
						</Col>
						<Col sm="3" xs="12">
							<Image style={styles.serviceImage} src={service4} fluid/>
						</Col>
					</Row>
					<Row style={styles.serviceRow}>
						<Col sm="3" xs="12">
							<Image style={styles.serviceImage} src={service5} fluid/>
						</Col>
						<Col sm="9" xs="12">
							<h3 style={styles.serviceTitle}>
								{t("services.service5.title")}
							</h3>
							<h5 style={styles.serviceText}>
								{t("services.service5.text")}
							</h5>
						</Col>
					</Row>
					<Row style={styles.serviceRow}>
						<Col sm="9" xs="12">
							<h3 style={styles.serviceTitle}>
								{t("services.service6.title")}
							</h3>
							<h5 style={styles.serviceText}>
								{t("services.service6.text")}
							</h5>
						</Col>
						<Col sm="3" xs="12">
							<Image style={styles.serviceImage} src={service6} fluid/>
						</Col>
					</Row>
				</Col>
			</Breakpoint>
			<Breakpoint small down>
				<Col sm={{span: 8, offset: 2}} xs={{span: 12, offset: 0}}>
					<Row>
						<Col style={styles.servicesHeader} sm={{ span: 10, offset: 1 }} xs={{span: 12, offset: 0}}>
							<h2 style={styles.headerTitle}>
								{t("services.servicesHeader.title")}
							</h2>
							<h5 style={styles.headerText}>
								{t("services.servicesHeader.text")}
							</h5>
							<Image style={styles.serviceImage} src={banner} fluid/>
						</Col>
					</Row>
					<Row style={styles.serviceRow}>
						<Col sm="3" xs="12">
							<Image style={styles.serviceImage} src={service1} fluid/>
						</Col>
						<Col sm="9" xs="12">
							<h3 style={styles.serviceTitle}>
								{t("services.service1.title")}
							</h3>
							<h5 style={styles.serviceText}>
								{t("services.service1.text")}
							</h5>
						</Col>
					</Row>
					<Row style={styles.serviceRow}>
						<Col sm="3" xs="12">
							<Image style={styles.serviceImage} src={service2} fluid/>
						</Col>
						<Col sm="9" xs="12">
							<h3 style={styles.serviceTitle}>
								{t("services.service2.title")}
							</h3>
							<h5 style={styles.serviceText}>
								{t("services.service2.text")}
							</h5>
						</Col>
					</Row>
					<Row style={styles.serviceRow}>
						<Col sm="3" xs="12">
							<Image style={styles.serviceImage} src={service3} fluid/>
						</Col>
						<Col sm="9" xs="12">
							<h3 style={styles.serviceTitle}>
								{t("services.service3.title")}
							</h3>
							<h5 style={styles.serviceText}>
								{t("services.service3.text")}
							</h5>
						</Col>
					</Row>
					<Row style={styles.serviceRow}>
						<Col sm="3" xs="12">
							<Image style={styles.serviceImage} src={service4} fluid/>
						</Col>
						<Col sm="9" xs="12">
							<h3 style={styles.serviceTitle}>
								{t("services.service4.title")}
							</h3>
							<h5 style={styles.serviceText}>
								{t("services.service4.text")}
							</h5>
						</Col>
					</Row>
					<Row style={styles.serviceRow}>
						<Col sm="3" xs="12">
							<Image style={styles.serviceImage} src={service5} fluid/>
						</Col>
						<Col sm="9" xs="12">
							<h3 style={styles.serviceTitle}>
								{t("services.service5.title")}
							</h3>
							<h5 style={styles.serviceText}>
								{t("services.service5.text")}
							</h5>
						</Col>
					</Row>
					<Row style={styles.serviceRow}>
						<Col sm="3" xs="12">
							<Image style={styles.serviceImage} src={service6} fluid/>
						</Col>
						<Col sm="9" xs="12">
							<h3 style={styles.serviceTitle}>
								{t("services.service6.title")}
							</h3>
							<h5 style={styles.serviceText}>
								{t("services.service6.text")}
							</h5>
						</Col>
					</Row>
				</Col>
			</Breakpoint>
			<div style={{height: "30vh"}}>

			</div>
			<Footer></Footer>
		</Container>
	);
}

export default Services;
