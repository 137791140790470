import colors from "../../themes/colors"
let styles = {
	container: {
		backgroundColor: colors.gaipWhite,
		display: "flex",
		flexDirection: "column",
	},
	particlesContainer: {
		position: "absolute",
		display: "flex",
		flexDirection: "center",
		alignItems: "center",
		justifyContent: "center",
		height: "100%",
		width: "100%"
	},
	titleContainer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
		height: "80vh",
		width: "100vw",
	},
	titleImage: {
		width: "33vw",
		objectFit: "contain",
		backgroundColor: "rgba(0,0,0,0)",
		zIndex: 10
	},
	titleImageMobile: {
		width: "66vw",
		objectFit: "contain",
		backgroundColor: "rgba(0,0,0,0)",
		zIndex: 10
	},
	homeColumnLeft: {
		display: "flex",
		height: "100vh",
		width: "50vw",
		flexDirection: "row",
		alignItems: "center", 
		justifyContent: "center",
	},
	homeColumnRight: {
		display: "flex",
		height: "100vh",
		width: "50vw",
		flexDirection: "row",
		alignItems: "center", 
		justifyContent: "center",
	},
	homeRowTop: {
		display: "flex",
		height: "50vh",
		width: "50vw",
		flexDirection: "row",
		alignItems: "center", 
		justifyContent: "center",
	},
	homeRowContainer: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		height: "80vh",
		width: "100vw",
	},
	homeRowBottom: {
		display: "flex",
		height: "30vh",
		width: "50vw",
		flexDirection: "column",
		alignItems: "center", 
	},
	carouselContainer: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		position: "relative",
		width: "33vw",
		height: "33vw"
	},
	carouselContainerMobile: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		position: "relative",
		width: "66vw",
		height: "66vw"
	},
	sliderStyle: {
		display: "flex",
		alignItems: "center", 
		justifyContent: "center"
	},
	mainSliderImage: {
		width: "33vw",
		height: "33vw",
		display: "flex",
		objectFit: "cover"
	},
	mainSliderImageMobile: {
		width: "66vw",
		height: "66vw",
		display: "flex",
		objectFit: "cover"
	},
	sliderMask: {
		width: "33vw",
		height: "33vw",
		objectFit: "cover",
		position: "absolute"
	},
	sliderMaskMobile: {
		width: "66vw",
		height: "66vw",
		objectFit: "cover",
		position: "absolute"
	},
	topShape: {
		display: "flex",
		height: "15vw",
		width: "15vw",
		objectFit: "contain",
		position: "absolute",
		right: "-10vw",
		top: 0
	},
	topShapeMobile: {
		display: "flex",
		height: "30vw",
		width: "30vw",
		objectFit: "contain",
		position: "absolute",
		right: "-20vw",
		top: 0
	},
	bottomShape: {
		display: "flex",
		height: "15vw",
		width: "15vw",
		objectFit: "contain",
		position: "absolute",
		right: "-10vw",
		bottom: 0
	},
	bottomShapeMobile: {
		display: "flex",
		height: "30vw",
		width: "30vw",
		objectFit: "contain",
		position: "absolute",
		right: "-20vw",
		bottom: 0
	},
	visionBackground: {
		height: "100vh",
		width: "100vw",
		objectFit: "cover",
		position: "absolute",
		opacity: .4,
	},
	sliderImage: {

	},
	gaipLogoSmall: {
		height: 40,
		objectFit: "contain"
	},
	gaipLogoTiny: {
		height: 20,
		objectFit: "contain"
	},
	mainTitle: {
		textAlign: "center",
	},
	quickScrollBar: {
		position: "fixed",
		alignSelf: "center",
		bottom: "20px",
		// backgroundColor: "rgba(254,254,254,.9)",
		backgroundColor: "white",
		minWidth: "300px",
		borderRadius: "1.5vh",
		zIndex: 100,
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-around",
		alignItems: "center",
		boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.3)",
	},
	navButton: {
		backgroundColor: "transparent",
		borderColor: "transparent",
		width: 72,
		padding: 0,
		margin: 0,
		height: 35,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		fontFamily: "Roboto-Regular",
		fontWeight: "900",
		color: colors.gaipBlue
	},
	aboutUsContainer: {
		minHeight: "100vh",
		paddingLeft: 10,
		paddingRight: 10
	},
	visionContainer: {
		minHeight: "100vh",
	},
	sectionMainCol: {
		justifyContent: "center",
		display: "flex",
		flexDirection: "column"
	},
	sectionTitle: {
		fontFamily: "Roboto-Regular",
		fontWeight: "100",
		color: colors.gaipGray,
		marginBottom: 20,
		zIndex: 10
	},
	sectionSubTitle: {
		fontFamily: "Roboto-Regular",
		color: "black",
		textAlign: "justify",
	},
	sectionTitleBlack: {
		fontFamily: "Roboto-Regular",
		fontWeight: "100",
		color: "black",
		marginBottom: 20
	},
	sectionSubTitleWhite: {
		fontFamily: "Roboto-Regular",
		color: "white",
		textAlign: "justify",
	},
	sectionText: {
		fontFamily: "Roboto-Regular",
		color: "black",
		textAlign: "justify"
	},
	missionPart3: {
		fontFamily: "Roboto-Regular",
		fontWeight: "bold",
		color: "black",
		textAlign: "justify",
	},
	sectionSplitter: {
		backgroundColor: colors.gaipBlue,
		height: 5,
		borderRadius: 2.5,
		marginTop: 40,
		marginBottom: 40
	},
	sectionSplitterWhite: {
		backgroundColor: colors.gaipWhite,
		height: 5,
		borderRadius: 2.5,
		marginTop: 40,
		marginBottom: 40
	},
	missionImage: {
		marginTop: 15,
		marginBottom: 30,
	},
	valueContainer: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	valueText: {
		fontFamily: "Roboto-Regular",
		fontWeight: "900",
		marginTop: 10
	},
	background1: {
		width: "60vw",
		objectFit: "contain",
		zIndex: 0,
		position: "absolute",
		alignSelf: "center"
	},
	background2: {
		width: "60vw",
		objectFit: "contain",
		zIndex: 0,
		position: "absolute",
		alignSelf: "center"
	},
	valuesBackground: {
		width: "100%",
		maxHeight: "700px",
		objectFit: "contain",
		zIndex: 0,
		
	},
}

export default styles