import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.js'
import { Slide, Zoom, Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	FormControl,
	Button,
	Tabs,
	Tab,
	Nav,
	ButtonGroup,
	ButtonToolbar,
	Modal,
	Alert,
	Toast,
	ListGroup
} from 'react-bootstrap'

import uuid from "react-uuid"

import routes from "../../api/routes.js"

import AuthCard from "../../components/authCard"
import Footer from "../../components/footer-component"
import colors from "../../themes/colors"
import { FaPlusCircle } from "react-icons/fa";

import image1 from '../../assets/gaipFullLogo.png'

import { adminMethods } from '../../api/adminMethods.js'

function ProjectsManager() {
	const { t, i18n } = useTranslation();
	const [buttonDisabled, setButtonDisabled] = useState(false)
	const [categoryKey, setCategoryKey] = useState(null);
	const [projectKey, setProjectKey] = useState(null);
	const [photoKey, setPhotoKey] = useState(null);
	const [showCreateCategory, setShowCreateCategory] = useState(false);
	const [showCreateProject, setShowCreateProject] = useState(false);
	const [showCreatePhoto, setShowCreatePhoto] = useState(false);
	const [showEditCategory, setShowEditCategory] = useState(false);
	const [showEditProject, setShowEditProject] = useState(false);
	const [showEditPhoto, setShowEditPhoto] = useState(false);
	const [showDeleteCategory, setShowDeleteCategory] = useState(false);
	const [showDeleteProject, setShowDeleteProject] = useState(false);
	const [showDeletePhoto, setShowDeletePhoto] = useState(false);
	const [showToast, setShowToast] = useState(false);
	const [toastText, setToastText] = useState("");
	const [toastMode, setToastMode] = useState("");
	const [categoryName, setCategoryName] = useState("")
	const [categories, setCategories] = useState([])
	const [projects, setProjects] = useState([])
	const [photos, setPhotos] = useState([])
	const [projectName, setProjectName] = useState("")
	const [projectDescription, setProjectDescription] = useState("")
	const [projectServices, setProjectServices] = useState("")
	const [projectTimeframe, setProjectTimeframe] = useState("")
	const [projectLocation, setProjectLocation] = useState("")
	const [photoDescription, setPhotoDescription] = useState("")
	const [photoFile, setPhotoFile] = useState(null)

	// CATEGORIES FUNCTIONS ---------------------------------------------

	const handleShowCreateCategory = () => setShowCreateCategory(true);
	const handleCloseCreateCategory = () => setShowCreateCategory(false);
	const handleShowEditCategory = () => {
		setCategoryName(categoryKey.name)
		setShowEditCategory(true)
	};
	const handleCloseEditCategory = () => setShowEditCategory(false);
	const handleShowDeleteCategory = () => setShowDeleteCategory(true);
	const handleCloseDeleteCategory = () => setShowDeleteCategory(false);
	const handleCategoryChange = async (k) => {
		if (categoryKey == null || (categoryKey != null && k.id != categoryKey.id)) {
			setCategoryKey(k)
			setProjectKey(null)
			setPhotoKey(null)
			setProjects([])
			await adminMethods.unsubProjects()
			getProjects(k.id)
		} else if(categoryKey != null && k.id == categoryKey.id){
			setCategoryKey(null)
			setProjectKey(null)
			setPhotoKey(null)
			setProjects([])
			await adminMethods.unsubProjects()
		}
	}
	const handleCategoryNameChange = e => {
		const { value } = e.target
		setCategoryName(value)
	}
	const getCategories = async () => {
		adminMethods.getCategories(setCategories)
	}
	const createCategory = async () => {
		setButtonDisabled(true)
		if(categoryName != ""){
			let response = await adminMethods.createCategory(categoryName)
			if (response) {
				setCategoryName("")
				handleCloseCreateCategory()
				handleToast("category", "success")
			} else {
				handleToast("category", "error")
			}
		}else{
			handleToast("emptyFields", "error")
		}
		setButtonDisabled(false)
	}
	const deleteCategory = async () => {
		setButtonDisabled(true)
		if(projects.length == 0 && photos.length == 0 && categoryKey != null){
			let response = await adminMethods.deleteCategory(categoryKey.id);
			if(response){
				handleCloseDeleteCategory()
				setCategoryKey(null)
				setProjectKey(null)
				setPhotoKey(null)
				await adminMethods.unsubProjects()
				await adminMethods.unsubPhotos()
				handleToast("categoryDelete", "success")
			}else{
				handleToast("categoryDelete", "error")
			}
		}
		setButtonDisabled(false)
	}
	const editCategory = async () => {
		setButtonDisabled(true)
		if(categoryName != "" && categoryKey != null){
			let response = await adminMethods.editCategory(categoryKey.id, categoryName);
			if(response){
				handleCloseEditCategory()
				setCategoryName("")
				handleToast("categoryEdit", "success")
			}else{
				handleToast("categoryEdit", "error")
			}
		}else{
			handleToast("emptyFields", "error")
		}
		setButtonDisabled(false)
	}

	// PROJECT FUNCTIONS ---------------------------------------------

	const handleShowCreateProject = () => setShowCreateProject(true);
	const handleCloseCreateProject = () => setShowCreateProject(false);
	const handleShowEditProject = () => {
		setProjectName(projectKey.name)
		setProjectDescription(projectKey.description)
		setProjectServices(projectKey.services)
		setProjectTimeframe(projectKey.timeframe)
		setProjectLocation(projectKey.location)
		setShowEditProject(true)
	};
	const handleCloseEditProject = () => setShowEditProject(false);
	const handleShowDeleteProject = () => setShowDeleteProject(true);
	const handleCloseDeleteProject = () => setShowDeleteProject(false);
	const handleProjectChange = async (k) => {
		if (projectKey == null || (projectKey != null && k.id != projectKey.id)) {
			setProjectKey(k)
			setPhotoKey(null)
			setPhotos([])
			await adminMethods.unsubPhotos()
			getPhotos(k.id)
		} else if(projectKey != null && k.id == projectKey.id){
			setProjectKey(null)
			setPhotoKey(null)
			setPhotos([])
			await adminMethods.unsubPhotos()
			getPhotos(k.id)
		}
	}
	const handleProjectNameChange = e => {
		const { value } = e.target
		setProjectName(value)
	}
	const handleProjectDescriptionChange = e => {
		const { value } = e.target
		setProjectDescription(value)
	}
	const handleProjectServicesChange = e => {
		const { value } = e.target
		setProjectServices(value)
	}
	const handleProjectTimeframeChange = e => {
		const { value } = e.target
		setProjectTimeframe(value)
	}
	const handleProjectLocationChange = e => {
		const { value } = e.target
		setProjectLocation(value)
	}
	const getProjects = async (k) => {
		adminMethods.getProjects(setProjects, k)
	}
	const createProject = async () => {
		setButtonDisabled(true)
		if(projectName != "" && projectDescription != "" && projectServices != "" && projectTimeframe != "" && projectLocation != "" && categoryKey != null){
			let response = await adminMethods.createProject(projectName, projectDescription, projectServices, projectTimeframe, projectLocation, categoryKey.id)
			if (response) {
				setProjectName("")
				setProjectDescription("")
				setProjectServices("")
				setProjectTimeframe("")
				setProjectLocation("")
				handleCloseCreateProject()
				handleToast("project", "success")
			} else {
				handleToast("project", "error")
			}
		}else{
			handleToast("emptyFields", "error")
		}
		setButtonDisabled(false)
	}
	const deleteProject = async () => {
		setButtonDisabled(true)
		if(photos.length == 0 && categoryKey != null && projectKey != null){
			let response = await adminMethods.deleteProject(categoryKey.id, projectKey.id);
			if(response){
				handleCloseDeleteProject()
				setProjectKey(null)
				setPhotoKey(null)
				await adminMethods.unsubPhotos()
				handleToast("projectDelete", "success")
			}else{
				handleToast("projectDelete", "error")
			}
		}
		setButtonDisabled(false)
	}
	const editProject = async () => {
		setButtonDisabled(true)
		if(projectName != "" && projectDescription != "" && projectServices != "" && projectTimeframe != "" && projectLocation != "" && categoryKey != null && projectKey != null){
			let response = await adminMethods.editProject(categoryKey.id, projectKey.id, projectName, projectDescription, projectServices, projectTimeframe, projectLocation);
			if(response){
				handleCloseEditProject()
				setProjectName("")
				setProjectDescription("")
				setProjectServices("")
				setProjectTimeframe("")
				setProjectLocation("")
				handleToast("projectEdit", "success")
			}else{
				handleToast("projectEdit", "error")
			}
		}else{
			handleToast("emptyFields", "error")
		}
		setButtonDisabled(false)
	}

	// PHOTOS FUNCTIONS ---------------------------------------------

	const handleShowCreatePhoto = () => setShowCreatePhoto(true);
	const handleCloseCreatePhoto = () => {
		setPhotoFile(null)
		setShowCreatePhoto(false)
	};
	const handleShowEditPhoto = () => {
		setPhotoDescription(photoKey.description)
		setShowEditPhoto(true)
	};
	const handleCloseEditPhoto = () => setShowEditPhoto(false);
	const handleShowDeletePhoto = () => setShowDeletePhoto(true);
	const handleCloseDeletePhoto = () => {
		setPhotoFile(null)
		setShowDeletePhoto(false)
	};
	const handlePhotoChange = async (photo) => {
		console.log(photo)
		if (photoKey == null || (photoKey != null && photo.id != photoKey.id)) {
			setPhotoKey(photo)
		} else if(photoKey != null && photo.id == photoKey.id){
			setPhotoKey(null)
		}
	}
	const handlePhotoDescriptionChange = e => {
		const { value } = e.target
		setPhotoDescription(value)
	}
	const getPhotos = async (k) => {
		adminMethods.getPhotos(setPhotos, categoryKey.id, k)
	}
	const createPhoto = async () => {
		setButtonDisabled(true)
		if(photoDescription != "" && photoFile != null && categoryKey != null && projectKey != null){
			let unique = uuid()
			let upload = await adminMethods.uploadImage(photoFile.file, unique)
			if (upload) {
				let response = await adminMethods.createPhoto(photoDescription, categoryKey.id, projectKey.id, unique)
				if (response) {
					setPhotoDescription("")
					setPhotoFile(null)
					handleCloseCreatePhoto()
					handleToast("photo", "success")
				} else {
					handleToast("photo", "error")
				}
			} else {
				handleToast("photo", "error")
			}
		}else{
			handleToast("emptyFields", "error")
		}
		setButtonDisabled(false)
	}
	const deleteImage = async () => {
		setButtonDisabled(true)
		if(categoryKey != null && projectKey != null && photoKey != null){
			let response = await adminMethods.deleteImage(categoryKey.id, projectKey.id, photoKey.id, photoKey.unique);
			if(response){
				handleCloseDeletePhoto()
				setPhotoKey(null)
				handleToast("photoDelete", "success")
			}else{
				handleToast("photoDelete", "error")
			}
		}
		setButtonDisabled(false)
	}
	const editImage = async () => {
		setButtonDisabled(true)
		if(photoDescription != "" && categoryKey != null && projectKey != null && photoKey != null){
			let response = await adminMethods.editPhoto(categoryKey.id, projectKey.id, photoKey.id, photoDescription);
			if(response){
				handleCloseEditPhoto()
				setPhotoDescription("")
				handleToast("photoEdit", "success")
			}else{
				handleToast("photoEdit", "error")
			}
		}else{
			handleToast("emptyFields", "error")
		}
		setButtonDisabled(false)
	}
	const onImageChange = event => {
		if (event.target.files && event.target.files[0]) {
			let reader = new FileReader();
			let file = event.target.files[0];
			reader.onloadend = () => {
				setPhotoFile({
					imagePreview: reader.result,
					file: file
				});
			};
			reader.readAsDataURL(file);
		}
	};

	// GENERAL FUNCTIONS ---------------------------------------------

	useEffect(() => {
		getCategories()
	}, []);

	const handleToast = (type, mode) => {
		let text = "";
		switch (type) {
			case "category":
				if (mode == "success") {
					text = t("toast.categorySuccess")
				} else {
					text = t("toast.categoryError")
				}
				break;
			case "project":
				if (mode == "success") {
					text = t("toast.projectSuccess")
				} else {
					text = t("toast.projectError")
				}
				break;
			case "photo":
				if (mode == "success") {
					text = t("toast.imageSuccess")
				} else {
					text = t("toast.imageError")
				}
				break;
			case "categoryDelete":
				if (mode == "success") {
					text = t("toast.categoryDeleteSuccess")
				} else {
					text = t("toast.categoryDeleteError")
				}
				break;
			case "projectDelete":
				if (mode == "success") {
					text = t("toast.projectDeleteSuccess")
				} else {
					text = t("toast.projectDeleteError")
				}
				break;
			case "photoDelete":
				if (mode == "success") {
					text = t("toast.imageDeleteSuccess")
				} else {
					text = t("toast.imageDeleteError")
				}
				break;
			case "categoryEdit":
				if (mode == "success") {
					text = t("toast.categoryUpdateSuccess")
				} else {
					text = t("toast.categoryUpdateError")
				}
				break;
			case "projectEdit":
				if (mode == "success") {
					text = t("toast.projectUpdateSuccess")
				} else {
					text = t("toast.projectUpdateError")
				}
				break;
			case "photoEdit":
				if (mode == "success") {
					text = t("toast.imageUpdateSuccess")
				} else {
					text = t("toast.imageUpdateError")
				}
				break;
			case "categoryNotEmpty":
				text = t("toast.categoryNotEmpty")
				break;
			case "projectNotEmpty":
				text = t("toast.projectNotEmpty")
				break;
			case "emptyFields":
				text = t("toast.emptyFields")
				break;

			default:
				break;
		}
		setToastText(text)
		setToastMode(mode)
		setShowToast(true)
	}

	return (
		<div>
			<Tab.Container id="list-group-tabs-example">
				<Row>
					<Col sm={3}>
						<div style={styles.columnHeader}>
							<h3>{t("admin.categoriesTitle")}</h3>
						</div>
						<ListGroup style={styles.categoriesColumn}>
							{categories.map((category) => {
								return <ListGroup.Item 
									style={styles.listItemStyle} 
									active={(categoryKey != null && categoryKey.id == category.id)} 
									action 
									onClick={() => handleCategoryChange(category)}
								>
									{category.name}
								</ListGroup.Item>
							})}
							{categories.length == 0 ? <div style={styles.noElementsContainer}>
								<p style={styles.noElementsText}>{t("admin.noCategories")}</p>
							</div> : null}
						</ListGroup>
						<ButtonToolbar aria-label="Toolbar with button groups">
							<Button onClick={handleShowCreateCategory} variant="link">
								{t("admin.create")}
							</Button>
							{categoryKey != null ? <ButtonGroup className="mr-2" aria-label="First group">
								<Button variant="link" onClick={handleShowEditCategory}>
									{t("admin.edit")}
								</Button>
							</ButtonGroup> : null}
							{categoryKey != null ? <ButtonGroup className="mr-2" aria-label="Second group">
								<Button variant="link" onClick={handleShowDeleteCategory}>
									{t("admin.delete")}
								</Button>
							</ButtonGroup> : null}
						</ButtonToolbar>
					</Col>
					<Col sm={9}>
						<Tab.Content>
							<Tab.Container id="list-group-tabs-example">
								<Row>
									<Col sm={4}>
										<div style={styles.columnHeader}>
											<h3>{t("admin.projectsTitle")}</h3>
										</div>
										{categoryKey == null ? <div>
											<p>{t("admin.selectCategory")}</p>
										</div> : <ListGroup style={styles.categoriesColumn}>
											{projects.map((project) => {
												return <ListGroup.Item 
													style={styles.listItemStyle} 
													active={(projectKey != null && projectKey.id == project.id)} 
													action 
													onClick={() => handleProjectChange(project)}
												>
													{project.name}
												</ListGroup.Item>
											})}
											{projects.length == 0 ? <div style={styles.noElementsContainer}>
												<p style={styles.noElementsText}>{t("admin.noProjects")}</p>
											</div> : null}
										</ListGroup>}
										<ButtonToolbar aria-label="Toolbar with button groups">
											{categoryKey != null ? 
												<Button 
													onClick={handleShowCreateProject} 
													variant="link"
												>
													{t("admin.create")}
												</Button> 
											: null}
											{projectKey != null ? 
												<ButtonGroup 
													className="mr-2" 
													aria-label="First group"
													>
												<Button variant="link" onClick={handleShowEditProject}>
													{t("admin.edit")}
												</Button>
											</ButtonGroup> : null}
											{projectKey != null ? 
												<ButtonGroup 
													className="mr-2" 
													aria-label="Second group"
												>
													<Button variant="link" onClick={handleShowDeleteProject}>
														{t("admin.delete")}
													</Button>
												</ButtonGroup>
											: null}
										</ButtonToolbar>
									</Col>
									<Col sm={8}>
										<Tab.Content>
											<div style={styles.columnHeader}>
												<h3>{t("admin.imagesTitle")}</h3>
											</div>
											{projectKey == null ? <div>
												<p>{t("admin.selectProject")}</p>
											</div> : <div style={styles.categoriesColumn}>
												{photos.map((photo) => {
													let url = routes.bucket + photo.unique + "?alt=media"
													return <ListGroup>
														<ListGroup.Item
															onClick={()=>handlePhotoChange(photo)} 
															variant={(photoKey != null && photoKey.id == photo.id) ? "primary" : "light"}
														>
															<Row>
																<Col xs={4}>
																	<div style={styles.projectImageContainer}>
																		<Image src={url} style={styles.projectImage}/>
																	</div>
																</Col>
																<Col xs={8}>
																	<p style={{whiteSpace: "pre-wrap"}}>{photo.description}</p>
																</Col>
															</Row>
														</ListGroup.Item>
													</ListGroup>
												})}
												{photos.length == 0 ? <div 
													style={styles.noElementsContainer}
												>
													<p 
														style={styles.noElementsText}
													>
															{t("admin.noImages")}
													</p>
											</div> : null}
											</div>}
										</Tab.Content>
										<ButtonToolbar aria-label="Toolbar with button groups">
											{projectKey != null ? 
												<Button 
													onClick={handleShowCreatePhoto} 
													variant="link"
												>
													{t("admin.addPhoto")}
												</Button> 
											: null}
											{photoKey != null ? 
												<ButtonGroup 
													className="mr-2" 
													aria-label="First group"
													>
												<Button variant="link" onClick={handleShowEditPhoto}>
													{t("admin.edit")}
												</Button>
											</ButtonGroup> : null}
											{photoKey != null ? 
												<ButtonGroup 
													className="mr-2" 
													aria-label="Second group"
												>
													<Button variant="link" onClick={handleShowDeletePhoto}>
														{t("admin.delete")}
													</Button>
												</ButtonGroup>
											: null}
										</ButtonToolbar>
									</Col>
								</Row>
							</Tab.Container>
						</Tab.Content>
					</Col>
				</Row>
			</Tab.Container>

			{/* CREATE CATEGORY MODAL --------------------------------------------- */}

			<Modal show={showCreateCategory} onHide={handleCloseCreateCategory}>
				<Modal.Header closeButton>
					<Modal.Title>{t("admin.createCategory")}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Form.Group controlId="categoryName">
							<Form.Label>{t("admin.categoryName")}</Form.Label>
							<Form.Control
								value={categoryName}
								type="text"
								placeholder=""
								onChange={handleCategoryNameChange}
								autoFocus={true}
							/>
							<Form.Text className="text-muted">
								{t("admin.categoryNameText")}
    						</Form.Text>
						</Form.Group>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseCreateCategory}>
						{t("admin.cancel")}
					</Button>
					<Button disabled={buttonDisabled} variant="primary" onClick={createCategory}>
						{t("admin.create")}
					</Button>
				</Modal.Footer>
			</Modal>

			{/* EDIT CATEGORY MODAL --------------------------------------------- */}

			<Modal show={showEditCategory} onHide={handleCloseEditCategory}>
				<Modal.Header closeButton>
					<Modal.Title>{t("admin.editCategory")}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Form.Group controlId="categoryName">
							<Form.Label>{t("admin.categoryName")}</Form.Label>
							<Form.Control
								value={categoryName}
								type="text"
								placeholder=""
								onChange={handleCategoryNameChange}
								autoFocus={true}
							/>
						</Form.Group>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseEditCategory}>
						{t("admin.cancel")}
					</Button>
					<Button disabled={buttonDisabled} variant="primary" onClick={editCategory}>
						{t("admin.update")}
					</Button>
				</Modal.Footer>
			</Modal>

			{/* CREATE PROJECT MODAL --------------------------------------------- */}

			<Modal show={showCreateProject} onHide={handleCloseCreateProject}>
				<Modal.Header closeButton>
					<Modal.Title>{t("admin.createProject")}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Form.Group controlId="projectName">
							<Form.Label>{t("admin.projectName")}</Form.Label>
							<Form.Control
								value={projectName}
								type="text"
								placeholder=""
								onChange={handleProjectNameChange}
							/>
						</Form.Group>
						<Form.Group controlId="projectDescription">
							<Form.Label>{t("admin.projectDescription")}</Form.Label>
							<Form.Control
								value={projectDescription}
								type="text"
								placeholder=""
								onChange={handleProjectDescriptionChange}
							/>
						</Form.Group>
						<Form.Group controlId="projectServices">
							<Form.Label>{t("admin.projectServices")}</Form.Label>
							<Form.Control
								value={projectServices}
								type="text"
								placeholder=""
								onChange={handleProjectServicesChange}
							/>
						</Form.Group>
						<Form.Group controlId="projectTimeframe">
							<Form.Label>{t("admin.projectTimeframe")}</Form.Label>
							<Form.Control
								value={projectTimeframe}
								type="text"
								placeholder=""
								onChange={handleProjectTimeframeChange}
							/>
						</Form.Group>
						<Form.Group controlId="projectLocation">
							<Form.Label>{t("admin.projectLocation")}</Form.Label>
							<Form.Control
								value={projectLocation}
								type="text"
								placeholder=""
								onChange={handleProjectLocationChange}
							/>
						</Form.Group>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseCreateProject}>
						{t("admin.cancel")}
					</Button>
					<Button disabled={buttonDisabled} variant="primary" onClick={createProject}>
						{t("admin.update")}
					</Button>
				</Modal.Footer>
			</Modal>

			{/* EDIT PROJECT MODAL --------------------------------------------- */}

			<Modal show={showEditProject} onHide={handleCloseEditProject}>
				<Modal.Header closeButton>
					<Modal.Title>{t("admin.editProject")}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Form.Group controlId="projectName">
							<Form.Label>{t("admin.projectName")}</Form.Label>
							<Form.Control
								value={projectName}
								type="text"
								placeholder=""
								onChange={handleProjectNameChange}
							/>
						</Form.Group>
						<Form.Group controlId="projectDescription">
							<Form.Label>{t("admin.projectDescription")}</Form.Label>
							<Form.Control
								value={projectDescription}
								type="text"
								placeholder=""
								onChange={handleProjectDescriptionChange}
							/>
						</Form.Group>
						<Form.Group controlId="projectServices">
							<Form.Label>{t("admin.projectServices")}</Form.Label>
							<Form.Control
								value={projectServices}
								type="text"
								placeholder=""
								onChange={handleProjectServicesChange}
							/>
						</Form.Group>
						<Form.Group controlId="projectTimeframe">
							<Form.Label>{t("admin.projectTimeframe")}</Form.Label>
							<Form.Control
								value={projectTimeframe}
								type="text"
								placeholder=""
								onChange={handleProjectTimeframeChange}
							/>
						</Form.Group>
						<Form.Group controlId="projectLocation">
							<Form.Label>{t("admin.projectLocation")}</Form.Label>
							<Form.Control
								value={projectLocation}
								type="text"
								placeholder=""
								onChange={handleProjectLocationChange}
							/>
						</Form.Group>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseEditProject}>
						{t("admin.cancel")}
					</Button>
					<Button disabled={buttonDisabled} variant="primary" onClick={editProject}>
						{t("admin.update")}
					</Button>
				</Modal.Footer>
			</Modal>

			{/* CREATE PHOTO MODAL --------------------------------------------- */}

			<Modal show={showCreatePhoto} onHide={handleCloseCreatePhoto}>
				<Modal.Header closeButton>
					<Modal.Title>{t("admin.addPhoto")}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Form.Group controlId="projectName">
							<Form.Label>{t("admin.imageDescription")}</Form.Label>
							<Form.Control
								value={photoDescription}
								type="text"
								placeholder=""
								onChange={handlePhotoDescriptionChange}
							/>
						</Form.Group>
						<Form.File id="formcheck-api-regular">
							<Form.File.Label>{t("admin.imageFile")}</Form.File.Label>
							<Form.File.Input
								accept="image/*"
								onChange={onImageChange}
								id="imageFileInput"
								name="image"
								type="file"
							/>
						</Form.File>
					</Form>
					{photoFile != null ? <Image src={photoFile.imagePreview} fluid /> : null}
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseCreatePhoto}>
						{t("admin.cancel")}
					</Button>
					<Button disabled={buttonDisabled} variant="primary" onClick={createPhoto}>
						{t("admin.create")}
					</Button>
				</Modal.Footer>
			</Modal>

			{/* EDIT PHOTO MODAL --------------------------------------------- */}

			<Modal show={showEditPhoto} onHide={handleCloseEditPhoto}>
				<Modal.Header closeButton>
					<Modal.Title>{t("admin.editPhoto")}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Form.Group controlId="projectName">
							<Form.Label>{t("admin.imageDescription")}</Form.Label>
							<Form.Control
								value={photoDescription}
								type="text"
								placeholder=""
								onChange={handlePhotoDescriptionChange}
							/>
						</Form.Group>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseEditPhoto}>
						{t("admin.cancel")}
					</Button>
					<Button disabled={buttonDisabled} variant="primary" onClick={editImage}>
						{t("admin.update")}
					</Button>
				</Modal.Footer>
			</Modal>

			{/* DELETE CATEGORY MODAL --------------------------------------------- */}
			
			<Modal show={showDeleteCategory} onHide={handleCloseDeleteCategory}>
				<Modal.Header closeButton>
					<Modal.Title>{t("admin.deleteCategory")}</Modal.Title>
				</Modal.Header>
				<Modal.Body>{t("admin.deleteCategoryText")}</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseDeleteCategory}>
						{t("admin.cancel")}
					</Button>
					<Button disabled={buttonDisabled} variant="primary" onClick={deleteCategory}>
						{t("admin.delete")}
					</Button>
				</Modal.Footer>
			</Modal>

			{/* DELETE PROJECT MODAL --------------------------------------------- */}
			
			<Modal show={showDeleteProject} onHide={handleCloseDeleteProject}>
				<Modal.Header closeButton>
					<Modal.Title>{t("admin.deleteProject")}</Modal.Title>
				</Modal.Header>
				<Modal.Body>{t("admin.deleteProjectText")}</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseDeleteProject}>
						{t("admin.cancel")}
					</Button>
					<Button disabled={buttonDisabled} variant="primary" onClick={deleteProject}>
						{t("admin.delete")}
					</Button>
				</Modal.Footer>
			</Modal>

			{/* DELETE PHOTO MODAL --------------------------------------------- */}

			<Modal show={showDeletePhoto} onHide={handleCloseDeletePhoto}>
				<Modal.Header closeButton>
					<Modal.Title>{t("admin.deletePhoto")}</Modal.Title>
				</Modal.Header>
				<Modal.Body>{t("admin.deletePhotoText")}</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseDeletePhoto}>
						{t("admin.cancel")}
					</Button>
					<Button disabled={buttonDisabled} variant="primary" onClick={deleteImage}>
						{t("admin.delete")}
					</Button>
				</Modal.Footer>
			</Modal>

			{/* GENERAL TOAST COMPONENT --------------------------------------------- */}

			<Toast
				show={showToast}
				onClose={() => setShowToast(false)}
				delay={3000}
				autohide
				style={toastMode == "success" ? styles.toastStyleSuccess : styles.toastStyleError}
			>
				<Toast.Body>
					{toastText}
				</Toast.Body>
			</Toast>
		</div>
	);
}

export default ProjectsManager;
