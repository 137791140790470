import styles from './styles.js'

import {
	Container,
	Row,
	Col,
	Nav,
	Navbar,
	NavDropdown,
	Image,
	Form,
	FormControl,
	Button,
} from 'react-bootstrap'
import UserProjects from '../../components/userProjects/index.js'

function App() {
	return (
		<Container style={styles.container} fluid>
			<UserProjects/>
		</Container>
	);
}

export default App;
