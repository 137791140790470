import React, {useState, useContext} from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.js'
import { Slide, Zoom, Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import {
	Container,
	Row,
	Col,
	Nav,
	Navbar,
	NavDropdown,
	Image,
	Form,
	FormControl,
	Button,
	Carousel,
	Card
} from 'react-bootstrap'

import image1 from '../../assets/gaipFullLogo.png'
import {firebaseAuth} from '../../provider/authProvider.js'

function AuthCard() {
	const {handleSignin, inputs, setInputs, errors,} = useContext(firebaseAuth)
	const { t, i18n } = useTranslation();
	
    const handleSubmit = (e) => {
		e.preventDefault()
		console.log('handleSubmit')
		handleSignin()
	}

	const handleChange = e => {
		const {name, value} = e.target
		console.log(inputs)
		setInputs(prev => ({...prev, [name]: value}))
	}

	return (
		<Card style={{ width: '18rem' }}>
            <Card.Img variant="top" src={image1} style={styles.logoImage} />
            <Card.Body>
                <Card.Title>Admin</Card.Title>
                <Form onSubmit={handleSubmit}>
                    <Form.Group>
                        <Form.Control 
                            id="email"
                            name="email" 
                            value={inputs.email}
                            type="email" 
                            placeholder={t("admin.email")} 
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Control 
                            id="password"
                            name="password" 
                            value={inputs.password}
                            type="password" 
                            placeholder={t("admin.password")} 
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Button
                        variant="primary" 
                        type="submit"
                    >
                        Submit
                    </Button>
                </Form>
                {errors.length > 0 ? errors.map(error => <p style={{color: 'red'}}>{error}</p> ) : null}
            </Card.Body>
        </Card>
	);
}

export default AuthCard;
