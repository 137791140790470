import colors from "../../themes/colors"
let styles = {
	servicesHeader: {
		display: "flex",
		alignItems: "center",
		flexDirection: "column",
		marginTop: 30,
		marginBottom: 30
	},
	headerTitle: {
		fontFamily: "Roboto-Regular",
		fontWeight: "900",
	},
	headerText: {
		fontFamily: "Roboto-Regular",
		fontWeight: "100",
		textAlign: "center"
	},
	serviceRow: {
		minHeight: "30vh",
		display: "flex",
		alignItems: "center"
	},
	serviceTitle: {
		fontFamily: "Roboto-Regular",
		fontWeight: "500",
		color: colors.gaipBlue
	},
	serviceText: {
		fontFamily: "Roboto-Regular",
		fontWeight: "100",
		textAlign: "justify"
	},
	serviceImage: {
		
	}
}

export default styles