import colors from "../../themes/colors"
let styles = {
	columnHeader: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
		width: "100%",
		marginTop: 30,
	},
	projectsTitle: {
		fontFamily: "Roboto-Regular",
		fontWeight: "900",
	},
	projectsTabBar: {
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
		justifyContent: "center", 
		alignItems: "center"
	},
	projectGrid: {
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
		justifyContent: "center",
		width: "100%",
		alignItems: "flex-start"
	},
	projectBlock: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "center", 
		alignItems: "center",
	},
	mainSliderImageMobile: {
		height: "50vh",
		width: "100%",
		objectFit: "contain"
	},
	sliderStyle: {
		display: "flex",
		alignItems: "center", 
		justifyContent: "center",
		width: "100%",
		overflow: "hidden",
		marginBottom: 30
	},
}

export default styles