import React, {useState, useContext} from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.js'
import { Slide, Zoom, Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import {
	Container,
	Row,
	Col,
	Nav,
	Navbar,
	NavDropdown,
	Image,
	Form,
	FormControl,
	Button,
	Carousel,
	Card
} from 'react-bootstrap'

import AuthCard from "../../components/authCard"
import AdminManager from "../../components/adminManager"
import Footer from "../../components/footer-component"
import colors from "../../themes/colors"

import image1 from '../../assets/gaipFullLogo.png'

import {firebaseAuth} from '../../provider/authProvider.js'

function Admin() {
	const {token} = useContext(firebaseAuth)
	const { t, i18n } = useTranslation();

	return (
		<Container style={styles.mainContainer} fluid>
			<Col style={styles.loginForm}>
				{token === null ? 
					<AuthCard/>
				:
					<AdminManager/>
				}
			</Col>
		</Container>
	);
}

export default Admin;
