import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.js'
import { Slide, Zoom, Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	FormControl,
	Button,
	Tabs,
	Tab,
	Nav,
	ButtonGroup,
	ButtonToolbar,
	Modal,
	Alert,
	Toast,
	ListGroup,
	Card
} from 'react-bootstrap'
import moment from "moment"

import uuid from "react-uuid"

import routes from "../../api/routes.js"

import AuthCard from "../../components/authCard"
import Footer from "../../components/footer-component"
import colors from "../../themes/colors"
import { FaPlusCircle } from "react-icons/fa";

import image1 from '../../assets/gaipIcon.png'
import LinkPreview from '@ashwamegh/react-link-preview'

// If you're using built in layout, you will need to import this css
import '@ashwamegh/react-link-preview/dist/index.css'

import { adminMethods } from '../../api/adminMethods.js'

function UserFeed() {
	const { t, i18n } = useTranslation();
	const [posts, setPosts] = useState([])

	const getPosts = async () => {
		adminMethods.getPosts(setPosts)
	}

	useEffect(() => {
		getPosts()
	}, []);

	return (
		<Row>
			<Col></Col>
			<Col xs={{span: 12, offset: 0}} sm={{span: 5, offset: 0}}>
				{posts.map((post)=>{
					let url = routes.bucket + post.unique + "?alt=media"
					let dateAgo = moment(post.created.toDate()).local().startOf('seconds').fromNow()
					return <Card style={{marginBottom: 10}}>
						<Card.Header>
							<Image style={{height: 30, marginRight: 10}} src={image1}/>
							GAiP
							<small 
								className="text-muted"
							>
								{" - " + dateAgo}
							</small>
						</Card.Header>
						{post.unique != "" ? <Card.Img variant="top" src={url} /> : null}
						<Card.Body>
							<Card.Title>
								{post.title}
							</Card.Title>
							<Card.Text>
								{post.text}
							</Card.Text>
							{post.link != "" ? 
								<Button 
									variant="link"
									onClick={(e) => {
										e.preventDefault();
										window.open(
											post.link,
											'_blank' // <- This is what makes it open in a new window.
										);
									}}
									style={{marginBottom: 10, marginTop: 10, width: "100%"}}
								> 
									Enlace: {post.link}
								</Button>
							: null}
						</Card.Body>
						<Card.Footer>
							<Row>
								<Col style={styles.leftFooterCol}>
									<small 
										className="text-muted"
									>
										{post.location}
									</small>
								</Col>
							</Row>
						</Card.Footer>
					</Card>
				})}
			</Col>
			<Col></Col>
		</Row>
	);
}

export default UserFeed;
