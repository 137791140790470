import colors from "../../themes/colors"
let styles = {
	servicesHeader: {
		display: "flex",
		flexDirection: "column",
		marginTop: 30,
		marginBottom: 30
	},
	headerTitle: {
		fontFamily: "Roboto-Regular",
		fontWeight: "900",
		textAlign: "center"
	},
	officeName: {
		fontFamily: "Roboto-Regular",
		fontWeight: "100",
		color: colors.gaipBlue,
		marginBottom: 10
	},
	mapContainer: {
		width: "100%",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		marginBottom: 30
	},
	mapFrame: {
		border: 0,
		display: "flex",
		width: "90%",
		height: 300,
		borderRadius: 10,
		boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.3)",
	},
	officeInfoContainer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		marginTop: 10
	},
	officeInfoSpacer: {
		height: "100%",
		width: "5px",
		backgroundColor: colors.gaipBlue,
		borderRadius: 2.5,
		marginRight: 10,
		marginLeft: 10,
	},
	iconContainer: {
		width: 30,
		height: 30,
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	contactRow: {
		marginBottom: 30,
	},
	contactInfo: {
		fontFamily: "Roboto-Regular",
		fontWeight: "bold",
		height: 10,
	},
	contactIcon: {
		color: colors.gaipBlue,
		fontSize: 30
	},
	gaipLogoTiny: {
		width: "20vh",
		objectFit: "contain",
		marginTop: 30,
		marginBottom: 30,
	},
}

export default styles