import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.js'
import { Slide, Zoom, Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	FormControl,
	Button,
	Tabs,
	Tab,
	Nav,
	ButtonGroup,
	ButtonToolbar,
	Modal,
	Alert,
	Toast,
	ListGroup,
	Carousel,
	Card,
	ToggleButton,
	Dropdown,
	DropdownButton,
	Spinner
} from 'react-bootstrap'

import routes from "../../api/routes.js"
import Footer from "../../components/footer-component"
import colors from "../../themes/colors"
import { FaPlusCircle } from "react-icons/fa";

import image1 from '../../assets/gaipFullLogo.png'

import { adminMethods } from '../../api/adminMethods.js'
import { Breakpoint } from 'react-socks';

import ProjectBlock from "./project"

import {FaChevronLeft, FaChevronRight} from "react-icons/fa";

import 'bootstrap/dist/css/bootstrap.min.css';


import "./gridstyle.css"

function UserProjects(props) {
	const { t, i18n } = useTranslation();
	const [categoryKey, setCategoryKey] = useState(null);
	const [categories, setCategories] = useState([])
	const [projects, setProjects] = useState([])
	const [loadingProjects, setLoadingProjects] = useState(true)
	const [selectedProject, setSelectedProject] = useState(null)

	// CATEGORIES FUNCTIONS ---------------------------------------------

	const handleCategoryChange = async (k) => {
		if (k.id != categoryKey.id) {
			setLoadingProjects(true)
			setCategoryKey(k)
			setProjects([])
			getProjects(k.id)
		}
	}
	const getCategories = async () => {
		adminMethods.getCategoriesUsers(setCategories)
	}

	const getProjects = async (k) => {
		adminMethods.getProjectsUsers(setProjects, k, setLoadingProjects)
	}

	// GENERAL FUNCTIONS ---------------------------------------------

	useEffect(() => {
		getCategories()
	}, []);

	const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	
	const openProjectModal = (project) => {
		setSelectedProject(project)
		handleShow()
	}

	return (
		<div style={{minHeight: "100vh", display: "flex", flexDirection: "column", alignItems: "center"}}>
			<div style={styles.columnHeader}>
				<h2 style={styles.projectsTitle}>{t("projectSection.ourProjects")}</h2>
			</div>
			<Breakpoint medium down>
				<DropdownButton id="dropdown-basic-button" title={categoryKey && categoryKey.name ? categoryKey.name : "Todos"}>
					{categories.map((category, index) => {
						if (categoryKey == null && index == 0) {
							setCategoryKey(category)
							getProjects(category.id)
						}
						return	<Dropdown.Item
							key={index}
							type="radio"
							variant="primary"
							name="radio"
							value={category.id}
							checked={(categoryKey != null && categoryKey.id == category.id)}
							onClick={() => handleCategoryChange(category)}
						>
							{category.name}
						</Dropdown.Item>
					})}
				</DropdownButton>
			</Breakpoint>
			<Breakpoint large up>
				<div style={styles.projectsTabBar}>
					<ButtonGroup toggle>
						{categories.map((category, index) => {
							if (categoryKey == null && index == 0) {
								setCategoryKey(category)
								getProjects(category.id)
							}
							return	<ToggleButton
								key={index}
								type="radio"
								variant="primary"
								name="radio"
								value={category.id}
								checked={(categoryKey != null && categoryKey.id == category.id)}
								onClick={() => handleCategoryChange(category)}
							>
								{category.name}
							</ToggleButton>
						})}
					</ButtonGroup>
				</div>
			</Breakpoint>
			{loadingProjects ?
				<div style={{marginTop: 30, marginBottom: 30}}>
					<Spinner animation="border" variant="primary" />
				</div>
			: <div style={styles.projectGrid}>
				{projects.map((project, index) => {
					let url = null
					if(project.photos.length >= 1){
						url = routes.bucket + project.photos[0].unique + "?alt=media"
					}else{
						url = ""
					}
					return <Col lg="3" md="4" sm="5" xs="12"
						style={styles.projectBlock}
					>
						<ProjectBlock project={project} url={url} openProjectModal={openProjectModal}/>
					</Col>
				})}
			</div>}
			<Modal 
				show={show} 
				onHide={handleClose}
				dialogClassName="main-modal"
			>
                <Modal.Header closeButton>
                <Modal.Title>
					{selectedProject != null ? selectedProject.name : ""}
				</Modal.Title>
                </Modal.Header>
                <Modal.Body>
					<Row>
						<Col lg="6" xs="12">
							<Carousel 
								controls={true} 
								indicators={true} 
								fade={false} 
								style={styles.sliderStyle}
								nextIcon={<div style={{height: 50, width: 50, display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "rgba(0,0,0,0.8)", borderRadius: 25}}>
									<FaChevronRight style={{fontSize: 30, color: colors.gaipWhite, }}/>
								</div>}
								prevIcon={<div style={{height: 50, width: 50, display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "rgba(0,0,0,0.8)", borderRadius: 25}}>
									<FaChevronLeft style={{fontSize: 30, color: colors.gaipWhite, }}/>
								</div>}
							>
								{selectedProject != null ? 
									selectedProject.photos.map((photo, index) => {
										let url = routes.bucket + photo.unique + "?alt=media"
										return <Carousel.Item interval={2000}>
											<Image 
												style={styles.mainSliderImageMobile}
												alt="" 
												src={url}
											/>
											<Carousel.Caption>
												<p style={{textShadow: "0px 0px 5px #000000"}}>{photo.description}</p>
											</Carousel.Caption>
										</Carousel.Item>
									})
								: null}
							</Carousel>
						</Col>
						<Col lg="6" xs="12">
							<b>{t("projectSection.description")}</b>
							<p>{selectedProject != null ? selectedProject.description : ""}</p>
							<b>{t("projectSection.services")}</b>
							<p>{selectedProject != null ? selectedProject.services : ""}</p>
							<b>{t("projectSection.timeframe")}</b>
							<p>{selectedProject != null ? selectedProject.timeframe : ""}</p>
						</Col>
					</Row>
				</Modal.Body>
                <Modal.Footer>
					<b>{selectedProject != null ? selectedProject.location : ""}</b>
                </Modal.Footer>
            </Modal>
		</div>
	);
}

export default UserProjects


{/* <div>
	<Carousel activeIndex={index} onSelect={handleSelect}>
		{photos.map((photo, index) => {
			let url = routes.bucket + photo.unique + "?alt=media"
			return <Carousel.Item>
				<Image
					fluid
					src={url}
					alt={photo.description}
					style={{width: "100%", objectFit: "contain", height: "40vh"}}
				/>
				<Carousel.Caption style={{bottom: 0}}>
					<p style={{backgroundColor: "rgba(0,0,0,0.5)", borderRadius: 10}}>{photo.description}</p>
				</Carousel.Caption>
			</Carousel.Item>
		})}
	</Carousel>
	<Col>
		<h3>{projectKey.name}</h3>
		<h5>Descripción: </h5>
		<p>{projectKey.description}</p>
		<h5>Alcance de los servicios: </h5>
		<p>{projectKey.services}</p>
		<h5>Plazo de ejecución: </h5>
		<p>{projectKey.timeframe}</p>
		<h5>Ubicación: </h5>
		<p>{projectKey.location}</p>
	</Col>
	{photos.length == 0 ? <div
		style={styles.noElementsContainer}
	>
		<p
			style={styles.noElementsText}
		>
			No hay imágenes
	</p>
	</div> : null}
</div> */}
