import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.js'
import { Slide, Zoom, Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	FormControl,
	Button,
	Tabs,
	Tab,
	Nav,
	ButtonGroup,
	ButtonToolbar,
	Modal,
	Alert,
	Toast,
	ListGroup
} from 'react-bootstrap'
import ProjectsManager from "../../components/projectsManager"
import FeedManager from "../../components/feedManager"
import Footer from "../../components/footer-component"
import colors from "../../themes/colors"
import { FaPlusCircle } from "react-icons/fa";

function Admin() {
	const { t, i18n } = useTranslation();
	const [modeKey, setModeKey] = useState('projects');

	const handleModeChange = (k) => {
		setModeKey(k)
	}

	return (
		<Container fluid style={{ height: "100%" }}>
			<Tabs
				id="modeTabs"
				activeKey={modeKey}
				onSelect={(k) => handleModeChange(k)}
			>
				<Tab eventKey="projects" title={t("admin.projects")}>
					<ProjectsManager/>
				</Tab>
				<Tab eventKey="feed" title={t("admin.feed")}>
					<FeedManager/>
				</Tab>
			</Tabs>
		</Container>
	);
}

export default Admin;
