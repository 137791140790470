import styles from './styles.js'

import {
	Container,
	Row,
	Col,
	Nav,
	Navbar,
	NavDropdown,
	Image,
	Form,
	FormControl,
	Button,
} from 'react-bootstrap'

function Footer() {
	return (
		<div style={styles.container}>
			
		</div>
	);
}

export default Footer;
