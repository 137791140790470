import { useTranslation } from 'react-i18next';
import styles from './styles.js'
import { Slide, Zoom, Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import {
	Container,
	Row,
	Col,
	Nav,
	Navbar,
	NavDropdown,
	Image,
	Form,
	FormControl,
	Button,
	Carousel,
} from 'react-bootstrap'

import Footer from "../../components/footer-component"
import colors from "../../themes/colors"

import routes from "../../api/routes"
import emailjs from 'emailjs-com'
import emailKeys from "../../api/emailKeys.js"
import GaipLogo from '../../assets/gaipLogo.png'
import ContactBackground from '../../assets/contacto.jpg'
import  { Breakpoint, BreakpointProvider } from 'react-socks';

import { FaPhone, FaMapMarkerAlt, FaEnvelope} from "react-icons/fa";

function onSubmit(e) {

	e.preventDefault()

	console.log(e)

	emailjs.sendForm('service_lbvl19m', emailKeys.TEMPLATE_ID, e.target, emailKeys.USER_ID)
		.then(result => {
			alert('Solicitud enviada, te contactaremos a la brevedad posible.', result.text);
		},
			error => {
				alert('Ocurrió un error, intenta más tarde.', error.text)
			})
	document.getElementById("contact-form").reset();
}

function ContactUs() {
	const { t, i18n } = useTranslation();

	return (
		<Container fluid>
			<Breakpoint small down>
				<Row style={{height: "40vh", marginBottom: 30}}>
					<Image fluid src={ContactBackground} style={{height: "100%", objectFit: "cover", width: "100%"}}/>
				</Row>
			</Breakpoint>
			<Breakpoint medium only>
				<Row style={{height: "50vh", marginBottom: 30}}>
					<Image fluid src={ContactBackground} style={{height: "100%", objectFit: "cover", width: "100%"}}/>
				</Row>
			</Breakpoint>
			<Breakpoint large up>
				<Row style={{height: "70vh", marginBottom: 30}}>
					<Image fluid src={ContactBackground} style={{height: "100%", objectFit: "cover", width: "100%"}}/>
				</Row>
			</Breakpoint>
			<Row>
				<Col style={styles.servicesHeader} sm={{ span: 6, offset: 0 }} xs={{ span: 12, offset: 0 }} style={{display: "flex", alignItems: "center", justifyContent: "flex-start", flexDirection :"column"}}>
					<h3 style={styles.headerTitle}>
						{t("contact.contactDirectly")}
					</h3>
					<h3 style={styles.officeName}>
						<Image fluid style={styles.gaipLogoTiny} alt="" src={GaipLogo} />
					</h3>
					<Row style={styles.contactRow}>
						<div style={styles.iconContainer}>
							<FaPhone style={styles.contactIcon} />
						</div>
						<div style={styles.officeInfoSpacer}></div>
						<div>
							<p style={styles.contactInfo}>+52 (33) 3640-3400</p>
							<p style={styles.contactInfo}>+52 (33) 3640-3401</p>
						</div>
					</Row>
					<Row style={styles.contactRow}>
						<div style={styles.iconContainer}>
							<FaEnvelope style={styles.contactIcon} />
						</div>
						<div style={styles.officeInfoSpacer}></div>
						<div>
							<p style={styles.contactInfo}>contacto@gaip.mx</p>
						</div>
					</Row>
				</Col>
				<Col style={styles.servicesHeader} sm={{ span: 6, offset: 0 }} xs={{ span: 12, offset: 0 }} style={{display: "flex", alignItems: "center", justifyContent: "flex-start", flexDirection :"column"}}>
					<h3 style={styles.headerTitle}>
						{t("contact.sendMessage")}
					</h3>
					<Form id="contact-form" name="contact-form" onSubmit={onSubmit}>
						<Form.Group controlId="contactForm.name">
							<Form.Label>{t("contact.name")}</Form.Label>
							<Form.Control name="name" type="text" placeholder="" />
						</Form.Group>
						<Form.Group controlId="contactForm.email">
							<Form.Label>{t("contact.email")}</Form.Label>
							<Form.Control name="email" type="email" placeholder={t("contact.emailExample")} />
						</Form.Group>
						<Form.Group controlId="contactForm.phone">
							<Form.Label>{t("contact.phone")}</Form.Label>
							<Form.Control name="phone" type="phone" placeholder="" />
						</Form.Group>
						<Form.Group controlId="contactForm.city">
							<Form.Label>{t("contact.city")}</Form.Label>
							<Form.Control name="city" type="text" placeholder="" />
						</Form.Group>
						<Form.Group controlId="contactForm.service">
							<Form.Label>{t("contact.service")}</Form.Label>
							<Form.Control name="service" as="select">
								<option value={t("services.service1.title")}>{t("services.service1.title")}</option>
								<option value={t("services.service2.title")}>{t("services.service2.title")}</option>
								<option value={t("services.service3.title")}>{t("services.service3.title")}</option>
								<option value={t("services.service4.title")}>{t("services.service4.title")}</option>
								<option value={t("services.service5.title")}>{t("services.service5.title")}</option>
								<option value={t("services.service6.title")}>{t("services.service6.title")}</option>
								<option value={t("contact.other")}>{t("contact.other")}</option>
							</Form.Control>
						</Form.Group>
						<Form.Group controlId="contactForm.message">
							<Form.Label>{t("contact.message")}</Form.Label>
							<Form.Control name="message" as="textarea" rows={3} />
						</Form.Group>
						<Button variant="primary" type="submit">
							{t("contact.submit")}
						</Button>
					</Form>
				</Col>
			</Row>
			<div style={{height: "10vh"}}></div>
			<Footer></Footer>
		</Container>
	);
}

export default ContactUs;
