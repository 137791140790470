import colors from "../../themes/colors"
let styles = {
	toastStyleSuccess: {
		position: "fixed",
		top: 20,
		right: 20,
		zIndex: 1000000,
		backgroundColor: colors.greenSuccess
	},
	toastStyleError: {
		position: "fixed",
		top: 20,
		right: 20,
		zIndex: 1000000,
		backgroundColor: colors.redError
	},
	leftFooterCol: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},
	rightFooterCol: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "flex-end"
	},
}

export default styles