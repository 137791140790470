import colors from "../../themes/colors"
let styles = {
	toastStyleSuccess: {
		position: "fixed",
		bottom: 20,
		left: 20,
		zIndex: 1000000,
		backgroundColor: colors.greenSuccess
	},
	toastStyleError: {
		position: "fixed",
		bottom: 20,
		left: 20,
		zIndex: 1000000,
		backgroundColor: colors.redError
	},
	mainContainer: {
		height: "100%", 
		width: "100%"
	},
	selectModeContainer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		backgroundColor: "white",
		width: "100%",
	},
	columnHeader: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		width: "100%"
	},
	categoriesColumn: {
		overflowY: 'scroll',
		overflow: "hidden",
		overflow: "-moz-scrollbars-vertical",
		whiteSpace: "nowrap",
		maxHeight: "70vh",
		backgroundColor: colors.gaipWhite,
		border: '1px solid ' + colors.lightGray,
		borderRadius: 10,
		marginTop: 10,
		marginRight: 10,
		width: "100%",
	},
	noElementsContainer: {
		width: "100%",
		flex: "display",
		alignItems: "center",
		justifyContent: "center",
		flexDirection: "row",
	},
	noElementsText: {
		textAlign: "center",
		marginTop: 10
	},
	projectImageContainer: {
		overflow: "hidden",
		margin: 10
	},
	projectImage: {
		objectFit: "contain",
		width: "100%",
		borderRadius: 20,
		border: '1px solid ' + colors.lightGray,
	}, 
	listItemStyle: {
		border: "0px", 
	}
}

export default styles