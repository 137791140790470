import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './core/i18n'
import AuthProvider from './provider/authProvider.js'
import firebase from 'firebase/app';
import 'firebase/firestore';
import  { Breakpoint, BreakpointProvider } from 'react-socks';
const db = firebase.firestore();
db.settings({
    timestampsInSnapshots: true
});
require('react-web-vector-icons/fonts');

ReactDOM.render(
	<Suspense fallback="loading">
		<AuthProvider>
			<BreakpointProvider>
				<BrowserRouter>
					<App />
				</BrowserRouter>
			</BreakpointProvider>
		</AuthProvider>
	</Suspense>,
	document.getElementById('root')
);
reportWebVitals();
