import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/app'
import 'firebase/firestore'
import 'firebase/analytics'

var firebaseConfig = {
    apiKey: "AIzaSyDNalCKkvZz1eIxWC4CVmdXFqnQo-LNDFI",
    authDomain: "gaip-platform.firebaseapp.com",
    projectId: "gaip-platform",
    storageBucket: "gaip-platform.appspot.com",
    messagingSenderId: "860092484462",
    appId: "1:860092484462:web:9db653fff5e4079d108f7f",
    measurementId: "G-4ED65S9JPV"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();
firebase.auth();
firebase.firestore()

export default {
    firebaseConfig, 
}