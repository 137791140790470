import { useTranslation } from 'react-i18next';
import styles from './styles.js'
import { Slide, Zoom, Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import {
	Row,
	Col,
	Image,
	Button,
	Carousel,
} from 'react-bootstrap'
import Particles from 'react-particles-js';

import AnchorLink from 'react-anchor-link-smooth-scroll'
import { SmoothProvider } from 'react-smooth-scrolling'

import Footer from "../../components/footer-component"
import colors from "../../themes/colors"

import FullLogo from '../../assets/gaipFullLogo.png'
import WhiteMask from '../../assets/whiteMask.png'
import TopShape from '../../assets/rombo_top.png'
import BottomShape from '../../assets/rombo_bottom.png'
import Background1 from '../../assets/rombos_background.png'
import Background2 from '../../assets/rombo_valores.png'
import Values from '../../assets/valores.png'
import GaipLogo from '../../assets/gaipLogo.png'
import image1 from '../../assets/sliders/1.jpg'
import image2 from '../../assets/sliders/2.jpg'
import image3 from '../../assets/sliders/3.jpg'
import image4 from '../../assets/sliders/4.jpg'
import image5 from '../../assets/sliders/5.jpg'
import visionBackground from '../../assets/vision.jpg'
import missionImage from '../../assets/mission.png'
import  { Breakpoint, BreakpointProvider } from 'react-socks';

function Home() {
	const { t, i18n } = useTranslation();
	const scrollToTop = () => {
		window.scrollTo({
		  top: 0,
		  behavior: "smooth"
		});
	  }
	return (
		<div style={styles.container} fluid>
			<section id="home">
				<Breakpoint small down>
					<div style={styles.homeRowContainer}>
						<div style={styles.homeRowTop}>
							<div style={styles.carouselContainerMobile}>
								<Carousel controls={false} indicators={false} fade={false} style={styles.sliderStyle}>
									<Carousel.Item interval={2000}>
										<Image 
											style={styles.mainSliderImageMobile}
											alt="" 
											src={image1}
										/>
									</Carousel.Item>
									<Carousel.Item interval={2000}>
										<Image 
											style={styles.mainSliderImageMobile}
											alt="" 
											src={image2} 
										/>
									</Carousel.Item>
									<Carousel.Item interval={2000}>
										<Image 
											style={styles.mainSliderImageMobile}
											alt="" 
											src={image3} 
										/>
									</Carousel.Item>
									<Carousel.Item interval={2000}>
										<Image 
											style={styles.mainSliderImageMobile}
											alt="" 
											src={image4} 
										/>
									</Carousel.Item>
									<Carousel.Item interval={2000}>
										<Image 
											style={styles.mainSliderImageMobile}
											alt="" 
											src={image5} 
										/>
									</Carousel.Item>
								</Carousel>
								<Image style={styles.sliderMaskMobile} alt="" src={WhiteMask} />
								<Image style={styles.topShapeMobile} alt="" src={TopShape} />
								<Image style={styles.bottomShapeMobile} alt="" src={BottomShape} />
							</div>
						</div>
						<div style={styles.homeRowBottom}>
							<Image style={styles.titleImageMobile} alt="" src={FullLogo} />
						</div>
						<div style={styles.particlesContainer}>
							<Particles 
								width="98vw" 
								height="92vh"
								params={{
									"particles": {
										"number": {
											"value": 25
										},
										"size": {
											"value": 4
										},
										"color": {
											"value": [
												colors.gaipBlue,
											]
										},
										"line_linked": {
											"enable": true,
											"distance": 150,
											"color": colors.gaipBlue,
											"opacity": 0.8,
											"width": 1
										},
									},
									"interactivity": {
										"events": {
											"onhover": {
												"enable": true,
												"mode": "repulse"
											}
										}
									},
								}}
							/>
						</div>
					</div>
				</Breakpoint>
				<Breakpoint medium up>
					<div style={styles.titleContainer}>
						<div style={styles.homeColumnLeft}>
							<div style={styles.carouselContainer}>
								<Carousel controls={false} indicators={false} fade={false} style={styles.sliderStyle}>
									<Carousel.Item interval={2000}>
										<Image 
											style={styles.mainSliderImage}
											alt="" 
											src={image1}
										/>
									</Carousel.Item>
									<Carousel.Item interval={2000}>
										<Image 
											style={styles.mainSliderImage}
											alt="" 
											src={image2} 
										/>
									</Carousel.Item>
									<Carousel.Item interval={2000}>
										<Image 
											style={styles.mainSliderImage}
											alt="" 
											src={image3} 
										/>
									</Carousel.Item>
									<Carousel.Item interval={2000}>
										<Image 
											style={styles.mainSliderImage}
											alt="" 
											src={image4} 
										/>
									</Carousel.Item>
									<Carousel.Item interval={2000}>
										<Image 
											style={styles.mainSliderImage}
											alt="" 
											src={image5} 
										/>
									</Carousel.Item>
								</Carousel>
								<Image style={styles.sliderMask} alt="" src={WhiteMask} />
								<Image style={styles.topShape} alt="" src={TopShape} />
								<Image style={styles.bottomShape} alt="" src={BottomShape} />
							</div>
						</div>
						<div style={styles.homeColumnRight}>
							<Image style={styles.titleImage} alt="" src={FullLogo} />
						</div>
						<div style={styles.particlesContainer}>
							<Particles 
								width="98vw" 
								height="92vh"
								params={{
									"particles": {
										"number": {
											"value": 25
										},
										"size": {
											"value": 4
										},
										"color": {
											"value": [
												colors.gaipBlue,
											]
										},
										"line_linked": {
											"enable": true,
											"distance": 150,
											"color": colors.gaipBlue,
											"opacity": 0.8,
											"width": 1
										},
									},
									"interactivity": {
										"events": {
											"onhover": {
												"enable": true,
												"mode": "repulse"
											}
										}
									},
								}}
							/>
						</div>
					</div>
				</Breakpoint>
			</section>
			<section id="aboutUs">
				<Row style={styles.aboutUsContainer}>
					<Col style={styles.sectionMainCol} md={{ span: 10, offset: 1 }} style={{display: "flex", justifyContent: "center", alignItems:"center"}}>
						<div style={{zIndex: 2}}>
							<h4 style={styles.sectionTitle}>
								{t("whoAreWe.title")}
							</h4>
							<h3 style={styles.sectionSubTitle}>
								<Image fluid style={styles.gaipLogoSmall} alt="" src={GaipLogo} />{t("whoAreWe.part1")}
							</h3>
							<div style={styles.sectionSplitter}></div>
							<Row>
								<Col xs="12" sm="6">
									<p style={styles.sectionText}>
										<Image fluid style={styles.gaipLogoTiny} alt="" src={GaipLogo} />{t("whoAreWe.part2")}
									</p>
								</Col>
								<Col xs="12" sm="6">
									<p style={styles.sectionText}>
										<Image fluid style={styles.gaipLogoTiny} alt="" src={GaipLogo} />{t("whoAreWe.part3")}
									</p>
								</Col>
							</Row>
						</div>
						
						<Image style={styles.background1} alt="" src={Background1} />
					</Col>
				</Row>
			</section>
			<section id="mission">
				<Row style={styles.aboutUsContainer}>
					<Col style={styles.sectionMainCol} md={{ span: 10, offset: 1 }} style={{display: "flex", justifyContent: "center", alignItems:"center"}}>
						<div style={{zIndex: 2}}>
							<h4 style={styles.sectionTitle}>
								{t("mission.title")}
							</h4>
							<Row>
								<Col md={{ span: 8, offset: 2 }}>
									<Image fluid style={styles.missionImage} alt="" src={missionImage} />
								</Col>
							</Row>
							<Row>
								<Col xs="12" sm="6">
									<p style={styles.sectionText}>
										{t("mission.part1")}
									</p>
								</Col>
								<Col xs="12" sm="6">
									<p style={styles.sectionText}>
										{t("mission.part2")}
									</p>
								</Col>
							</Row>
							<Row>
								<Col md={{ span: 8, offset: 2 }}>
									<p style={styles.missionPart3}>
										{t("mission.part3")}
									</p>
								</Col>
							</Row>
						</div>
						<Image style={styles.background1} alt="" src={Background2} />
					</Col>
				</Row>
			</section>
			<section id="vision">
				<Row style={styles.aboutUsContainer}>
					<Image fluid style={styles.visionBackground} alt="" src={visionBackground} />
					<Col style={styles.sectionMainCol} md={{ span: 10, offset: 1 }}>
						<div style={{backgroundColor: "rgba(0,0,0,0)", borderRadius: 30, padding: 20}}>
							<h4 style={styles.sectionTitleBlack}>
								{t("vision.title")}
							</h4>
							<h3 style={styles.sectionSubTitle}>
								{t("vision.part1")}
							</h3>
							<div style={styles.sectionSplitter}></div>
						</div>
					</Col>
				</Row>
			</section>
			<section id="values">
				<Row style={styles.aboutUsContainer}>
					<Col style={styles.sectionMainCol} md={{ span: 10, offset: 1 }} style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
						{/*<div style={{zIndex: 2}}>
							<h4 style={styles.sectionTitle}>
								{t("values.title")}
							</h4>
							<Row>
								<Col style={styles.valueContainer} xs="6" sm={{ span: 2, offset: 1 }}>
									<Image fluid style={styles.missinoImage} alt="" src={teamWorkImage} />
									<p style={styles.valueText}>
										{t("values.teamWork")}
									</p>
								</Col>
								<Col style={styles.valueContainer} xs="6" sm="2">
									<Image fluid style={styles.missinoImage} alt="" src={innovationImage} />
									<p style={styles.valueText}>
										{t("values.innovation")}
									</p>
								</Col>
								<Col style={styles.valueContainer} xs="6" sm="2">
									<Image fluid style={styles.missinoImage} alt="" src={reliabilityImage} />
									<p style={styles.valueText}>
										{t("values.reliability")}
									</p>
								</Col>
								<Col style={styles.valueContainer} xs="6" sm="2">
									<Image fluid style={styles.missinoImage} alt="" src={honestyImage} />
									<p style={styles.valueText}>
										{t("values.honesty")}
									</p>
								</Col>
								<Col style={styles.valueContainer} xs={{ span: 6, offset: 3 }} sm={{ span: 2, offset: 0 }}>
									<Image fluid style={styles.missinoImage} alt="" src={loyaltyImage} />
									<p style={styles.valueText}>
										{t("values.loyalty")}
									</p>
								</Col>
							</Row>
						</div>*/}
						<h4 style={styles.sectionTitle}>
							{t("values.title")}
						</h4>
						<Image style={styles.valuesBackground} alt="" src={Values} />
					</Col>
				</Row>
			</section>
			<Row  className="d-none d-sm-block" style={{position: "fixed", bottom: 0, width: "100%", zIndex: 10}}>
				<Col xs="0" sm="12" style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
					<div style={styles.quickScrollBar}>
						<Button variant="light" offset='0' style={styles.navButton} onClick={scrollToTop}>
							{t('navigation.navHome')}
						</Button>
						<Button variant="light" offset='0' style={styles.navButton} as={AnchorLink} href='#aboutUs'>
							{t('navigation.navAboutUs')}
						</Button>
						<Button variant="light" offset='0' style={styles.navButton} as={AnchorLink} href='#mission'>
							{t('navigation.navMission')}
						</Button>
						<Button variant="light" offset='0' style={styles.navButton} as={AnchorLink} href='#vision'>
							{t('navigation.navVision')}
						</Button>
						<Button variant="light" offset='0' style={styles.navButton} as={AnchorLink} href='#values'>
							{t('navigation.navValues')}
						</Button>
					</div>
				</Col>
			</Row>
			<Footer></Footer>
		</div>
	);
}

export default Home;
