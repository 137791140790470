import React, {useState, useContext} from "react";
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link,
	Redirect
} from "react-router-dom";

import {firebaseAuth} from './provider/authProvider.js'

import {
	Container,
	Row,
	Col,
	Nav,
	Navbar,
	NavDropdown,
	Image,
	Form,
	FormControl,
	Button,
	ButtonGroup,
	ToggleButton,
	Modal
} from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import ScrollToTop from "./components/scrollToTop"

import Home from "./views/home-view"
import Services from "./views/services-view"
import Projects from "./views/projects-view"
import Feed from "./views/feed-view"
import Contact from "./views/contact-view"
import Admin from "./views/admin-view"

import GAiPIcon from './assets/gaipIcon.png'
import MexicoFlag from './assets/mexico.png'
import USFlag from "./assets/united-states.png"
import i18next from "i18next";
import colors from "./themes/colors"

// import Drift from "react-driftjs";

let styles = {
	navBarStyle: {
		// backgroundColor: "rgba(254,254,254,.9)"
		backgroundColor: "white",
		boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.3)",
	},
	langBtnSelected: {
		backgroundColor: colors.gaipBlue,
		borderColor: "transparent",
		fontFamily: "Roboto-Regular",
		fontWeight: "900"
	},
	langBtn: {
		backgroundColor: colors.lightGray,
		borderColor: "transparent",
		fontFamily: "Roboto-Regular",
		fontWeight: "900"
	},
	textLink: {
		fontFamily: "Roboto-Regular",
		fontWeight: "900",
		color: colors.gaipBlue,
	}
}

export default function App() {
	const { handleSignout, token} = useContext(firebaseAuth)
	const { t, i18n } = useTranslation();
	const [spanishSet, setSpanish] = useState(true);
	const [englishSet, setEnglish] = useState(false);
	
	function setEsLanguage(){
		setSpanish(true)
		setEnglish(false)
		i18next
			.changeLanguage('es')
			.then((t) => {
				t('key'); // -> same as i18next.t
			});
	}

	function setEnLanguage(){
		setSpanish(false)
		setEnglish(true)
		i18next
			.changeLanguage('en')
			.then((t) => {
				t('key'); // -> same as i18next.t
			});
	}

	const signOut = e => {
		handleClose()
		handleSignout()
	}

	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	return (
		<Router>
			<Navbar fixed="top" style={styles.navBarStyle} expand="sm">
				<Navbar.Brand as={Link} to="/">
					<Image style={{height: 50}} alt="" src={GAiPIcon}/>
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav">
					<Nav className="mr-auto">
						<Nav.Link as={Link} to="/" style={styles.textLink}>{t('navigation.navHome')}</Nav.Link>
						<Nav.Link as={Link} to="/services" style={styles.textLink}>{t('navigation.navServices')}</Nav.Link>
						<Nav.Link as={Link} to="/projects" style={styles.textLink}>{t('navigation.navProjects')}</Nav.Link>
						<Nav.Link as={Link} to="/feed" style={styles.textLink}>{t('navigation.navFeed')}</Nav.Link>
						<Nav.Link as={Link} to="/contact" style={styles.textLink}>{t('navigation.navContact')}</Nav.Link>
						{token === null ? 
							null
						: 
							<Nav.Link 
								as={Link} 
								to="/gaipadmin" 
								style={styles.textLink}
							>
								Admin
							</Nav.Link>
						}
					</Nav>
					{token === null ? 
						null
					: 
						<ButtonGroup toggle className="mb-2">
							<Button onClick={handleShow} variant="link">
								Sign Out
							</Button>
						</ButtonGroup>
					}
					<ButtonGroup toggle className="mb-2">
						<ToggleButton
							style={spanishSet ? styles.langBtnSelected : styles.langBtn}
							type="checkbox"
							variant="primary"
							checked={spanishSet}
							value="1"
							onChange={()=>setEsLanguage()}
						>
							<Image style={{height: 30}} alt="" src={MexicoFlag}/>
						</ToggleButton>
						<ToggleButton
							style={englishSet ? styles.langBtnSelected : styles.langBtn}
							type="checkbox"
							variant="primary"
							checked={englishSet}
							value="2"
							onChange={()=>setEnLanguage()}
						>
							<Image style={{height: 30}} alt="" src={USFlag}/>
						</ToggleButton>
					</ButtonGroup>
				</Navbar.Collapse>
			</Navbar>
			<div style={{height: 80, width: "100%"}}></div>
			<ScrollToTop/>
			{/* <Drift
				appId="wgw8tmdntcep"
			/> */}
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Cerrar sesión</Modal.Title>
				</Modal.Header>
				<Modal.Body>¿Deseas cerrar la sesión?</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Cancelar
				</Button>
					<Button variant="primary" onClick={signOut}>
						Cerrar sesión
				</Button>
				</Modal.Footer>
			</Modal>
			<Switch>
				<Route exact path="/">
					<Home />
				</Route>
				<Route path="/services">
					<Services />
				</Route>
				<Route path="/projects">
					<Projects />
				</Route>
				<Route path="/feed">
					<Feed />
				</Route>
				<Route path="/contact">
					<Contact />
				</Route>
				<Route path="/gaipadmin">
					<Admin/>
				</Route>
				<Redirect to='/' />
			</Switch>
		</Router>
	);
}
