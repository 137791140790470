import firebaseConfig from './firebase'
import firebase from 'firebase/app'
import 'firebase/firestore';
import 'firebase/storage'
const db = firebase.firestore();
var storage = firebase.storage();
var storageRef = storage.ref();
let unsubscribeCategories = null;
let unsubscribeProjects = null;
let unsubscribePhotos = null;

async function removeImageFromBucket(unique){
	var imageRef = storageRef.child('images/' + unique);
	imageRef.delete().then(function() {
		console.log("IMAGE DELETE SUCCESS")
	}).catch(function(error) {
		console.log("IMAGE DELETE ERROR: ", error)
	});
}

export const adminMethods = {
	createCategory: async (name) => {
		let result = null
		await db.collection("categories")
			.add({
				name: name,
				created: firebase.firestore.Timestamp.now()
			})
			.then(function (docRef) {
				console.log("Document written with ID: ", docRef.id);
				result = true
			})
			.catch(function (error) {
				console.error("Error adding document: ", error);
				result = false
			});
		return result
	},
	getCategories: async (setCategories) => {
		unsubscribeCategories = db.collection("categories")
			.orderBy('created', 'asc')
			.onSnapshot(function (queryData) {
				var categories = [];
				var documentData = null
				queryData.forEach(function (doc) {
					documentData = doc.data()
					documentData = {
						...documentData,
						id: doc.id
					}
					categories.push(documentData);
				});
				setCategories(categories)
			});
	},
	unsubCategories: async () => {
		if (unsubscribeCategories != null) {
			unsubscribeCategories()
		}
	},
	createProject: async (name, description, services, timeframe, location, categoryId) => {
		let result = null
		await db.collection("categories/" + categoryId + "/projects")
			.add({
				name: name,
				description: description,
				services: services,
				timeframe: timeframe,
				location: location,
				created: firebase.firestore.Timestamp.now()
			})
			.then(function (docRef) {
				console.log("Document written with ID: ", docRef.id);
				result = true
			})
			.catch(function (error) {
				console.error("Error adding document: ", error);
				result = false
			});
		return result
	},
	getProjects: async (setProjects, categoryId) => {
		unsubscribeProjects = db.collection("categories/" + categoryId + "/projects")
			.orderBy('created', 'asc')
			.onSnapshot(function (queryData) {
				var projects = [];
				var documentData = null
				queryData.forEach((doc) => {
					documentData = doc.data()
					documentData = {
						...documentData,
						id: doc.id
					}
					projects.push(documentData);
				});
				setProjects(projects)
			});
	},
	unsubProjects: async () => {
		if (unsubscribeProjects != null) {
			unsubscribeProjects()
		}
	},
	createPhoto: async (description, categoryId, projectId, unique) => {
		let result = null
		await db.collection("categories/" + categoryId + "/projects/" + projectId + "/images")
			.add({
				description: description,
				unique: unique,
				created: firebase.firestore.Timestamp.now()
			})
			.then(function (docRef) {
				console.log("Document written with ID: ", docRef.id);
				result = true
			})
			.catch(function (error) {
				console.error("Error adding document: ", error);
				result = false
			});
		return result
	},
	getPhotos: async (setPhotos, categoryId, projectId) => {
		unsubscribePhotos = db.collection("categories/" + categoryId + "/projects/" + projectId + "/images")
			.orderBy('created', 'asc')
			.onSnapshot(function (queryData) {
				var photos = [];
				var documentData = null
				queryData.forEach(function (doc) {
					documentData = doc.data()
					documentData = {
						...documentData,
						id: doc.id
					}
					photos.push(documentData);
				});
				console.log(photos)
				setPhotos(photos)
			});
	},
	unsubPhotos: async () => {
		if (unsubscribePhotos != null) {
			unsubscribePhotos()
		}
	},
	uploadImage: async (imageFile, unique) => {
		try {
			const file = imageFile
			const metadata = {
				contentType: imageFile.type
			};
			console.log(imageFile, unique, metadata)
			const uploadTaskSnapshot = await storageRef.child('images/' + unique).put(file, metadata);
			const downloadURL = await uploadTaskSnapshot.ref.getDownloadURL();
			console.log(downloadURL)
			return downloadURL;
		} catch (error) {
			console.log(error)
			return false	
		}
	},
	getImageUrl: async (unique) => {
		var pathReference = storage.ref('images/' + unique);
		let URL = await pathReference.getDownloadURL()
		console.log(URL)
		return URL;
	},
	deleteCategory: async (categoryId) => {
		let result = null;
		await db.collection("categories")
		.doc(categoryId)
		.delete()
		.then(function() {
			result = true
			console.log("Document successfully deleted!");
		}).catch(function(error) {
			result = false
			console.error("Error removing document: ", error);
		});
		return result
	},
	deleteProject: async (categoryId, projectId) => {
		let result = null;
		await db.collection("categories/" + categoryId + "/projects")
		.doc(projectId)
		.delete()
		.then(function() {
			result = true
			console.log("Document successfully deleted!");
		}).catch(function(error) {
			result = false
			console.error("Error removing document: ", error);
		});
		return result
	},
	deleteImage: async (categoryId, projectId, imageId, unique) => {
		let result = null;
		removeImageFromBucket(unique)
		await db.collection("categories/" + categoryId + "/projects/" + projectId + "/images")
		.doc(imageId)
		.delete()
		.then(function() {
			result = true
			console.log("Document successfully deleted!");
		}).catch(function(error) {
			result = false
			console.error("Error removing document: ", error);
		});
		return result
	},
	editCategory: async (categoryId, name) => {
		let result = null;
		await db.collection("categories/")
		.doc(categoryId)
		.update({
			name: name
		})
		.then(function() {
			result = true
			console.log("Document successfully updated!");
		})
		.catch(function(error) {
			result = false
			console.error("Error updating document: ", error);
		});
		return result
	},
	editProject: async (categoryId, projectId, name, description, services, timeframe, location) => {
		let result = null;
		await db.collection("categories/" + categoryId + "/projects/")
		.doc(projectId)
		.update({
			name: name,
			description: description,
			services: services,
			timeframe: timeframe,
			location: location
		})
		.then(function() {
			result = true
			console.log("Document successfully updated!");
		})
		.catch(function(error) {
			result = false
			console.error("Error updating document: ", error);
		});
		return result
	},
	editPhoto: async (categoryId, projectId, photoId, description) => {
		let result = null;
		await db.collection("categories/" + categoryId + "/projects/" + projectId + "/images")
		.doc(photoId)
		.update({
			description: description,
		})
		.then(function() {
			result = true
			console.log("Document successfully updated!");
		})
		.catch(function(error) {
			result = false
			console.error("Error updating document: ", error);
		});
		return result
	},
	getPosts: async (setPosts) => {
		unsubscribePhotos = db.collection("posts/")
			.orderBy('created', 'desc')
			.onSnapshot(function (queryData) {
				var posts = [];
				var documentData = null
				queryData.forEach(function (doc) {
					documentData = doc.data()
					documentData = {
						...documentData,
						id: doc.id
					}
					posts.push(documentData);
				});
				console.log(posts)
				setPosts(posts)
			});
	},
	createPost: async (title, text, location, link, unique) => {
		let result = null
		await db.collection("posts/")
			.add({
				title: title,
				text: text,
				location: location,
				link: link,
				unique: unique,
				created: firebase.firestore.Timestamp.now()
			})
			.then(function (docRef) {
				console.log("Document written with ID: ", docRef.id);
				result = true
			})
			.catch(function (error) {
				console.error("Error adding document: ", error);
				result = false
			});
		return result
	},
	deletePost: async (postId, unique) => {
		let result = null;
		removeImageFromBucket(unique)
		await db.collection("posts/")
		.doc(postId)
		.delete()
		.then(function() {
			result = true
			console.log("Document successfully deleted!");
		}).catch(function(error) {
			result = false
			console.error("Error removing document: ", error);
		});
		return result
	},
	editPost: async (title, text, location, link, postId) => {
		let result = null;
		await db.collection("posts/")
		.doc(postId)
		.update({
			title: title,
			text: text,
			location: location,
			link: link,
		})
		.then(function() {
			result = true
			console.log("Document successfully updated!");
		})
		.catch(function(error) {
			result = false
			console.error("Error updating document: ", error);
		});
		return result
	},
	getCategoriesUsers: async (setCategories) => {
		let categoriesRef = db.collection('categories');
		let queryData = await categoriesRef.orderBy('created', 'asc').get();
		var categories = [];
		var documentData = null
		queryData.docs.forEach(function (doc) {
			documentData = doc.data()
			documentData = {
				...documentData,
				id: doc.id
			}
			categories.push(documentData);
		});
		setCategories(categories)
	},
	getProjectsUsers: async (setProjects, categoryId, setLoadingProjects) => {
		let projectsRef =  db.collection("categories/" + categoryId + "/projects")
		let queryData = await projectsRef.orderBy('created', 'asc').get();
		var projects = [];
		for(const doc of queryData.docs){
			var documentData = doc.data()
			documentData = {
				...documentData,
				id: doc.id
			}
			let projectData = await db.collection("categories/" + categoryId + "/projects/" + doc.id + "/images").orderBy('created', 'asc').get()
			if(projectData.docs.length >= 1){
				let photos = []
				projectData.docs.forEach((doc)=>{
					let photo = {
						...doc.data(),
						id: doc.id
					}
					photos.push(photo)
				})
				documentData = {
					...documentData,
					photos,
				}
			}
			projects.push(documentData);
		}
		console.log(projects)
		setProjects(projects)
		setLoadingProjects(false)
	},
}