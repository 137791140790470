import colors from "../../themes/colors"
let styles = {
	mainContainer: {
		height: "88vh",
		backgroundColor: colors.gaipWhite
	},
	loginForm: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		height: "100%"
	},
	logoImage: {
		padding: 20
	},
	serviceImage: {
		borderRadius: 10,
		boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.3)",
		border: '5px solid ' + colors.gaipWhite
	}
}

export default styles