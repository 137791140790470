import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.js'
import { Slide, Zoom, Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import {
	Image,
	Button,
	Modal,
	Carousel,
	Card,
	Col,
	Row
} from 'react-bootstrap'

import routes from "../../api/routes.js"
import Footer from "../../components/footer-component"
import colors from "../../themes/colors"
import { FaPlusCircle } from "react-icons/fa";

import image1 from '../../assets/gaipFullLogo.png'

import { adminMethods } from '../../api/adminMethods.js'
import { Breakpoint } from 'react-socks';

import WhiteMask from '../../assets/whiteMask.png'
import "./gridstyle.css"

function ProjectBlock(props) {
	const [isShown, setIsShown] = useState(false);
    const project = props.project
    const url = props.url

	const openProject = () => {
        props.openProjectModal(project)
    }

	return (
		<div
			onClick={openProject}
			onMouseEnter={() => setIsShown(true)}
        	onMouseLeave={() => setIsShown(false)}
			style={{marginTop: 30, display: "flex", flex: 1, flexDirection: "column", alignItems: "center"}}
		>
			<div class="square">
				<div class="content">
					<Card.Img  src={url} style={{width: "100%", objectFit: "cover", height: "100%"}}/>
				</div>
				{isShown && (
					<div style={{position: "absolute", display: "flex", alignItems :"center", justifyContent: "center", zIndex: 10, backgroundColor: "rgba(255,255,255,0.8)", width: "100%"}}>
						<h5 style={{textAlign: "center", marginLeft: 50, marginRight: 50, fontWeight: "900", fontFamily: "Roboto-Regular", marginTop: 30, marginBottom: 30}}>{project.name}</h5>
					</div>
				)}
				<Image style={{width: "100%", objectFit: "cover", height: "100%", position: "absolute", zIndex: 11}} alt="" src={WhiteMask} />
			</div>
		</div>
	);
}

export default ProjectBlock

{/*<Card onClick={()=>openProject(project)} style={{marginTop: 30, display: "flex", flex: 1, position: "relative"}}>
    <Card.Img variant="top" src={url} fluid style={{objectFit: "cover", width: "100%", display: "block", height: "auto"}}/>
    <Card.Body>
        <Card.Title>{project.name}</Card.Title>
    </Card.Body>
</Card> */}

{/*<div
    ref={el => {
        if (!el) return;
        setImageHeight(el.getBoundingClientRect().width)
    }}
    onClick={()=>openProject(project)} 
    style={{marginTop: 30, display: "flex", position: "relative", flexDirection: "column", flex: 1,}}>
    <div style={{height: imageHeight == 0 ? "auto" : imageHeight}}>
        <img src={url} style={{width: "100%", objectFit: "cover", height: "100%"}}/>
    </div>
    <div>
        <h3>{project.name}</h3>
    </div>
</div>  */}
