import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.js'
import { Slide, Zoom, Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	FormControl,
	Button,
	Tabs,
	Tab,
	Nav,
	ButtonGroup,
	ButtonToolbar,
	Modal,
	Alert,
	Toast,
	ListGroup,
	Card
} from 'react-bootstrap'
import moment from "moment"

import uuid from "react-uuid"

import routes from "../../api/routes.js"

import AuthCard from "../../components/authCard"
import Footer from "../../components/footer-component"
import colors from "../../themes/colors"
import { FaPlusCircle } from "react-icons/fa";

import image1 from '../../assets/gaipIcon.png'
import LinkPreview from '@ashwamegh/react-link-preview'

// If you're using built in layout, you will need to import this css
import '@ashwamegh/react-link-preview/dist/index.css'

import { adminMethods } from '../../api/adminMethods.js'

function FeedManager() {
	const { t, i18n } = useTranslation();
	const [buttonDisabled, setButtonDisabled] = useState(false)
	const [postKey, setPostKey] = useState(null);
	const [showCreatePost, setShowCreatePost] = useState(false);
	const [showEditPost, setShowEditPost] = useState(false);
	const [showDeletePost, setShowDeletePost] = useState(false);
	const [showToast, setShowToast] = useState(false);
	const [toastText, setToastText] = useState("");
	const [toastMode, setToastMode] = useState("");
	const [posts, setPosts] = useState([])
	const [postTitle, setPostTitle] = useState("")
	const [postText, setPostText] = useState("")
	const [postLocation, setPostLocation] = useState("")
	const [postLink, setPostLink] = useState("")
	const [postFile, setPostFile] = useState(null)

	// POSTS FUNCTIONS ---------------------------------------------
	// CREATE
	const handleShowCreatePost = () => setShowCreatePost(true);
	const handleCloseCreatePost = () => setShowCreatePost(false);
	// EDIT
	const handleShowEditPost = () => setShowEditPost(true);
	const handleCloseEditPost = () => setShowEditPost(false);
	// DELETE
	const handleShowDeletePost = () => setShowDeletePost(true);
	const handleCloseDeletePost = () => setShowDeletePost(false);

	const handlePostTitleChange = e => {
		const { value } = e.target
		setPostTitle(value)
	}
	const handlePostTextChange = e => {
		const { value } = e.target
		setPostText(value)
	}
	const handlePostLocationChange = e => {
		const { value } = e.target
		setPostLocation(value)
	}
	const handlePostLinkChange = e => {
		const { value } = e.target
		setPostLink(value)
	}
	const getPosts = async () => {
		adminMethods.getPosts(setPosts)
	}
	const createPost = async () => {
		setButtonDisabled(true)
		if(postTitle != "" && postText != ""){
			let unique = uuid()
			if(postFile != null){
				let upload = await adminMethods.uploadImage(postFile.file, unique)
			}else{
				unique = ""
			}
			let response = await adminMethods.createPost(postTitle, postText, postLocation, postLink, unique)
			if (response) {
				setPostTitle("")
				setPostText("")
				setPostLocation("")
				setPostLink("")
				setPostFile(null)
				handleCloseCreatePost()
				handleToast("post", "success")
			} else {
				handleToast("post", "error")
			}
		}else{
			handleToast("emptyFields", "error")
		}
		setButtonDisabled(false)
	}
	const deletePost = async () => {
		setButtonDisabled(true)
		let response = await adminMethods.deletePost(postKey.id, postKey.unique);
		if(response){
			handleCloseDeletePost()
			setPostKey(null)
			handleToast("postDelete", "success")
		}else{
			handleToast("postDelete", "error")
		}
		setButtonDisabled(false)
	}
	const editPost = async () => {
		setButtonDisabled(true)
		if(postTitle != "" && postText != ""){
			let response = await adminMethods.editPost(postTitle, postText, postLocation, postLink, postKey.id);
			if(response){
				handleCloseEditPost()
				setPostTitle("")
				setPostText("")
				setPostLocation("")
				setPostLink("")
				handleToast("postEdit", "success")
			}else{
				handleToast("postEdit", "error")
			}
		}
		setButtonDisabled(false)
	}
	const onImageChange = event => {
		if (event.target.files && event.target.files[0]) {
			let reader = new FileReader();
			let file = event.target.files[0];
			reader.onloadend = () => {
				setPostFile({
					imagePreview: reader.result,
					file: file
				});
			};
			reader.readAsDataURL(file);
		}
	};

	// GENERAL FUNCTIONS ---------------------------------------------

	useEffect(() => {
		getPosts()
	}, []);

	const handleToast = (type, mode) => {
		let text = "";
		switch (type) {
			case "post":
				if (mode == "success") {
					text = t("toast.postSuccess")
				} else {
					text = t("toast.postError")
				}
				break;
			case "postDelete":
				if (mode == "success") {
					text = t("toast.postDeleteSuccess")
				} else {
					text = t("toast.postDeleteError")
				}
				break;
			case "postEdit":
				if (mode == "success") {
					text = t("toast.postUpdateSuccess")
				} else {
					text = t("toast.postUpdateError")
				}
				break;
			case "emptyFields":
				text = t("toast.emptyFields")
				break;

			default:
				break;
		}
		setToastText(text)
		setToastMode(mode)
		setShowToast(true)
	}

	const selectEditPost = (post) => {
		setPostTitle(post.title)
		setPostText(post.text)
		setPostLocation(post.location)
		setPostLink(post.link)
		setPostKey(post)
		handleShowEditPost()
	}

	const selectDeletePost = (post) => {
		setPostKey(post)
		handleShowDeletePost()
	}

	return (
		<div>
			<Tab.Container id="list-group-tabs-example">
				<Row>
					<Col></Col>
					<Col xs={{span: 12, offset: 0}} sm={{span: 5, offset: 0}}>
						<Button 
							onClick={handleShowCreatePost} 
							variant="primary"
							style={{marginBottom: 10, marginTop: 10, width: "100%"}}
						>
							<h3>{t("admin.createPost")}</h3>
						</Button>
						{posts.map((post)=>{
							let url = routes.bucket + post.unique + "?alt=media"
							let dateAgo = moment(post.created.toDate()).local().startOf('seconds').fromNow()
							return <Card style={{marginBottom: 10}}>
								<Card.Header>
									<Image style={{height: 30, marginRight: 10}} src={image1}/>
									GAiP
								</Card.Header>
								{post.unique != "" ? <Card.Img variant="top" src={url} /> : null}
								<Card.Body>
									<Card.Title>
										{post.title}
									</Card.Title>
									<Card.Text>
										{post.text}
									</Card.Text>
									{post.link != "" ? 
										<Button 
											variant="link"
											onClick={(e) => {
												e.preventDefault();
												window.open(
													post.link,
													'_blank'
												);
											}}
											style={{marginBottom: 10, marginTop: 10, width: "100%"}}
										> 
											{t("admin.link")}{post.link}
										</Button>
									: null}
								</Card.Body>
								<Card.Footer>
									<Row>
										<Col style={styles.leftFooterCol}>
											<small 
												className="text-muted"
											>
												{post.location != "" ? post.location + " - " : ""}{dateAgo}
											</small>
										</Col>
										<Col style={styles.rightFooterCol}>
											<Button onClick={()=>selectEditPost(post)} variant="link">
												{t("admin.edit")}
											</Button>
											<Button onClick={()=>selectDeletePost(post)} variant="link">
												{t("admin.delete")}
											</Button>
										</Col>
									</Row>
								</Card.Footer>
							</Card>
						})}
					</Col>
					<Col></Col>
				</Row>
			</Tab.Container>

			{/* CREATE POST MODAL --------------------------------------------- */}

			<Modal show={showCreatePost} onHide={handleCloseCreatePost}>
				<Modal.Header closeButton>
					<Modal.Title>{t("admin.createPost")}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Form.Group controlId="projectName">
							<Form.Label>{t("admin.postTitle")}</Form.Label>
							<Form.Control
								value={postTitle}
								type="text"
								placeholder=""
								onChange={handlePostTitleChange}
							/>
						</Form.Group>
						<Form.Group controlId="projectName">
							<Form.Label>{t("admin.postText")}</Form.Label>
							<Form.Control
								value={postText}
								as="textarea"
								rows={6}
								placeholder=""
								onChange={handlePostTextChange}
							/>
						</Form.Group>
						<Form.Group controlId="projectName">
							<Form.Label>{t("admin.postLocation")}</Form.Label>
							<Form.Control
								value={postLocation}
								type="text"
								placeholder=""
								onChange={handlePostLocationChange}
							/>
						</Form.Group>
						<Form.Group controlId="projectName">
							<Form.Label>{t("admin.postLink")}</Form.Label>
							<Form.Control
								value={postLink}
								type="text"
								placeholder=""
								onChange={handlePostLinkChange}
							/>
						</Form.Group>
						<Form.File id="formcheck-api-regular">
							<Form.File.Label>{t("admin.postImage")}</Form.File.Label>
							<Form.File.Input
								accept="image/*"
								onChange={onImageChange}
								id="imageFileInput"
								name="image"
								type="file"
							/>
						</Form.File>
					</Form>
					{postFile != null ? <Image src={postFile.imagePreview} fluid /> : null}
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseCreatePost}>
						{t("admin.cancel")}
					</Button>
					<Button disabled={buttonDisabled} variant="primary" onClick={createPost}>
						{t("admin.create")}
					</Button>
				</Modal.Footer>
			</Modal>

			{/* EDIT POST MODAL --------------------------------------------- */}

			<Modal show={showEditPost} onHide={handleCloseEditPost}>
				<Modal.Header closeButton>
					<Modal.Title>{t("admin.editPost")}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Form.Group controlId="projectName">
							<Form.Label>{t("admin.postTitle")}</Form.Label>
							<Form.Control
								value={postTitle}
								type="text"
								placeholder=""
								onChange={handlePostTitleChange}
							/>
						</Form.Group>
						<Form.Group controlId="projectName">
							<Form.Label>{t("admin.postText")}</Form.Label>
							<Form.Control
								value={postText}
								as="textarea"
								rows={6}
								placeholder=""
								onChange={handlePostTextChange}
							/>
						</Form.Group>
						<Form.Group controlId="projectName">
							<Form.Label>{t("admin.postLocation")}</Form.Label>
							<Form.Control
								value={postLocation}
								type="text"
								placeholder=""
								onChange={handlePostLocationChange}
							/>
						</Form.Group>
						<Form.Group controlId="projectName">
							<Form.Label>{t("admin.postLink")}</Form.Label>
							<Form.Control
								value={postLink}
								type="text"
								placeholder=""
								onChange={handlePostLinkChange}
							/>
						</Form.Group>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseEditPost}>
						{t("admin.cancel")}
					</Button>
					<Button disabled={buttonDisabled} variant="primary" onClick={editPost}>
						{t("admin.update")}
					</Button>
				</Modal.Footer>
			</Modal>

			{/* DELETE POST MODAL --------------------------------------------- */}

			<Modal show={showDeletePost} onHide={handleCloseDeletePost}>
				<Modal.Header closeButton>
					<Modal.Title>{t("admin.deletePost")}</Modal.Title>
				</Modal.Header>
				<Modal.Body>{t("admin.deletePostText")}</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseDeletePost}>
						{t("admin.cancel")}
					</Button>
					<Button disabled={buttonDisabled} variant="primary" onClick={deletePost}>
						{t("admin.delete")}
					</Button>
				</Modal.Footer>
			</Modal>

			{/* GENERAL TOAST COMPONENT --------------------------------------------- */}

			<Toast
				show={showToast}
				onClose={() => setShowToast(false)}
				delay={3000}
				autohide
				style={toastMode == "success" ? styles.toastStyleSuccess : styles.toastStyleError}
			>
				<Toast.Body>
					{toastText}
				</Toast.Body>
			</Toast>
		</div>
	);
}

export default FeedManager;
